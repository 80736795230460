import { IconPlugConnected } from '@tabler/icons-react';
import { Image } from '../../../../design-system/v2';

interface OperatorIconProps {
  iconUrl: string;
  size?: number;
}

export const OperatorIcon = ({ iconUrl, size = 32 }: OperatorIconProps) => {
  if (iconUrl.length === 0) {
    return <IconPlugConnected size={size} />;
  }

  return <Image src={iconUrl} width={size} height={size} />;
};
