import { useMarkovTheme } from '../../design-system/v2';
import { AppBuilderStatusKeys } from '../../generated/api';

export const useAppStatusDetails = (appStatus: Nullable<AppBuilderStatusKeys>) => {
  const theme = useMarkovTheme();

  if (!appStatus) {
    return;
  }

  switch (appStatus) {
    case AppBuilderStatusKeys.Draft:
      return {
        title: 'Draft',
        color: theme.colors.yellow,
      };
    case AppBuilderStatusKeys.Published:
      return {
        title: 'Published',
        color: theme.colors.green,
      };
    case AppBuilderStatusKeys.Archived:
      return {
        title: 'Archived',
        color: theme.colors.gray,
      };
    default:
      return {
        title: 'Unknown',
        color: theme.colors.gray,
      };
  }
};
