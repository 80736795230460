import { useFlags } from 'launchdarkly-react-client-sdk';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { ArtifactStateType, Workflow } from '../../../../../generated/api';
import { useToggleLockWorkflowModal } from '../../../actions/lock-unlock/use-toggle-lock';
import { useWorkflowClone } from '../../../list/workflow-actions/useCloneWorkflow';
import { useWorkflowAsJsonExport } from '../../../list/workflow-actions/useExportJsonTemplate';
import { useWorkflowExport } from '../../../list/workflow-actions/useExportWorkflow';
import { useWorkflowArchiveUnarchive } from '../../../list/workflow-actions/useWorkflowArchiveUnarchive';
import { useWorkflowDelete } from '../../../list/workflow-actions/useWorkflowDelete';

interface WorkflowAction {
  id: string;
  label: string;
  handler: () => void;
  visible: boolean;
}

export const useWorkflowActions = (workflow?: Workflow): WorkflowAction[] => {
  const { featureDevTemplateExport } = useFlags();
  const { userId } = useAppMetadata();

  const workflowId = workflow?.workflowId ?? '';
  const state = (workflow?.artifactState.state ?? ArtifactStateType.Draft) as ArtifactStateType;

  const confirmToggleLockWorkflow = useToggleLockWorkflowModal(
    workflowId,
    state === ArtifactStateType.Active ? ArtifactStateType.Draft : ArtifactStateType.Active,
  );
  const { openArchiveUnarchiveModal } = useWorkflowArchiveUnarchive([workflowId], state);
  const { openDeleteModal } = useWorkflowDelete([workflowId]);
  const { openCloneModal } = useWorkflowClone(workflowId);
  const { openExportModal } = useWorkflowExport(workflowId);
  const { openExportWorkflowAsJsonModal } = useWorkflowAsJsonExport(workflowId);

  const isWorkflowOwner = workflow?.createdBy === userId;

  const archiveAction = {
    id: 'archive-workflow',
    label: state === ArtifactStateType.Archived ? 'Unarchive' : 'Archive',
    handler: openArchiveUnarchiveModal,
    visible: true,
  };

  if (state === ArtifactStateType.Archived) {
    return [archiveAction];
  }

  const toggleLockAction = {
    id: 'toggle-lock-workflow',
    label: state === ArtifactStateType.Active ? 'Unlock' : 'Lock',
    handler: confirmToggleLockWorkflow,
    visible: isWorkflowOwner,
  };
  const deleteAction = {
    id: 'delete-workflow',
    label: 'Delete',
    handler: openDeleteModal,
    visible: true,
  };
  const cloneAction = {
    id: 'clone-workflow',
    label: 'Clone',
    handler: openCloneModal,
    visible: true,
  };
  const exportAsTemplateAction = {
    id: 'export-workflow',
    label: 'Save As Template',
    handler: openExportModal,
    visible: true,
  };
  const exportAsJsonTemplateAction = {
    id: 'export-json-workflow',
    label: 'Devs Only Export',
    handler: openExportWorkflowAsJsonModal,
    visible: featureDevTemplateExport,
  };

  return [
    toggleLockAction,
    cloneAction,
    archiveAction,
    deleteAction,
    exportAsTemplateAction,
    exportAsJsonTemplateAction,
  ].filter(action => action.visible);
};
