import { notifications } from '../../../design-system/v2';
import { AppBuilder, LLMModel, UpdateAppBuilderRequest } from '../../../generated/api';
import { useUpdateAppBuilderMutation } from '../../../queries/app-builder/app-builder';
import { AppBuilderDetailsFormWrapper } from './AppBuilderDetailsForm.wrapper';

export interface AppBuilderDetailsFormContainerProps {
  appId: string;
  prompt: string;
  example: string;
  onPromptChange: (prompt: string) => void;
  onExampleChange: (example: string) => void;
  appBuilderDetails: AppBuilder;
  selectedModel: LLMModel;
}

export const AppBuilderDetailsFormContainer = ({
  appId,
  prompt,
  example,
  onPromptChange,
  onExampleChange,
  appBuilderDetails,
  selectedModel,
}: AppBuilderDetailsFormContainerProps) => {
  const { mutateAsync: updateAppBuilder } = useUpdateAppBuilderMutation(appId);

  const updateAppMetadata = async (prompt: string, example: string) => {
    const request: UpdateAppBuilderRequest = {
      appProperties: {
        appContext: '',
        appPrompt: prompt.trim(),
        examples: [example.trim()],
        models: selectedModel!,
      },
    };
    await updateAppBuilder(request).catch(() => {
      notifications.error('Unable to save data');
    });
  };

  return (
    <AppBuilderDetailsFormWrapper
      prompt={prompt}
      example={example}
      onPromptChange={onPromptChange}
      onExampleChange={onExampleChange}
      appDetails={appBuilderDetails}
      onFormValuesChange={updateAppMetadata}
    />
  );
};
