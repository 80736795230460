import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const QnAWithDoc = lazyWithRetry(() => import('../pages/AppStore/QnAWithDoc/QnAWithDoc'));
const QnAWithDocDetails = lazyWithRetry(
  () => import('../pages/AppStore/QnAWithDoc/QnAWithDocDetails'),
);

const docQnARoutes: RouteProps[] = [
  {
    path: '/',
    element: <QnAWithDoc />,
  },
  {
    path: getRoute(AppRoutes.DOC_QNA_DETAILS),
    element: <QnAWithDocDetails />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const DocQnARouter = (): JSX.Element => <Routes>{docQnARoutes.map(renderAuthRoute)}</Routes>;
