import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { Text, openModal } from '../../../../design-system/v2';
import { sendAnalytics } from '../../../../initializers/analytics';
import { TemplatesModalContainer } from './TemplateModal.container';

export const useWorkflowTemplateModal = () => {
  const { workspaceId } = useAppMetadata();

  const handleClose = () => {
    sendAnalytics(workflowEvents.create.cancel({ workspaceId }));
  };

  const open = () =>
    openModal({
      title: (
        <Text variant="subTitle01" p="sm">
          Select a Template
        </Text>
      ),
      size: '60vw',
      padding: '24px',
      children: <TemplatesModalContainer />,
      onClose: handleClose,
    });

  return { open };
};
