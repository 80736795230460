import { IconCloudCheck, IconCloudExclamation, IconCloudUp } from '@tabler/icons-react';
import noop from 'lodash/noop';
import { useEffect, useState } from 'react';
import { ActionIcon, Horizontal, Text, Tooltip } from '../../../design-system/v2';

interface SaveComponentProps {
  icon: React.ReactNode;
  color: 'green' | 'gray' | 'blue' | 'red';
  text: string;
  showText?: boolean;
}
const SaveComponent = ({ icon, text, showText, color }: SaveComponentProps) => (
  <Tooltip label={text} withinPortal>
    <Horizontal>
      <ActionIcon
        size="sm"
        variant="transparent"
        color={color}
        onClick={noop}
        sx={{ pointerEvents: 'none' }}
      >
        {icon}
      </ActionIcon>
      {showText && (
        <Text variant="small01" color={color}>
          {text}
        </Text>
      )}
    </Horizontal>
  </Tooltip>
);

interface SaveIndicatorProps {
  isSaving?: boolean;
  isSaveSuccess?: boolean;
  isSaveError?: boolean;
}

export const SaveIndicator = ({ isSaving, isSaveSuccess, isSaveError }: SaveIndicatorProps) => {
  const [showText, setShowText] = useState(true);
  const [savedIconColor, setSavedIconColor] = useState<'gray' | 'green'>('gray');

  useEffect(() => {
    if (isSaveSuccess) {
      // turn to gray after 1.2s
      const timer = setTimeout(() => {
        setShowText(false);
        setSavedIconColor('gray');
      }, 1200);

      return () => clearTimeout(timer);
    } else {
      setShowText(true); // Reset to show text when not in save success state
      setSavedIconColor('green');
    }
  }, [isSaveSuccess]);

  if (isSaving) {
    return <SaveComponent icon={<IconCloudUp />} color="blue" text="Saving changes" showText />;
  }

  if (isSaveError) {
    return (
      <SaveComponent icon={<IconCloudExclamation />} color="red" text="Could not save" showText />
    );
  }

  return (
    <SaveComponent
      icon={<IconCloudCheck />}
      color={savedIconColor}
      text="All changes saved"
      showText={showText}
    />
  );
};
