import { Horizontal, Pagination, PaginationLabel } from '../../../../design-system/v2';
import { DEFAULT_PAGE_SIZE } from '../../../../design-system/v2/core/data-display/table/util';

export interface WorkflowRunsListFooterProps {
  totalRows: number;
  currentPage: number;
  onPageChange: (page: number) => void;
}

export const WorkflowRunsListFooter = ({
  totalRows,
  currentPage,
  onPageChange,
}: WorkflowRunsListFooterProps) =>
  totalRows > 0 ? (
    // TODO: Create a reusable component which wraps PaginationLabel and Pagination
    <Horizontal py="xxl" position="center">
      <PaginationLabel
        labelRowsPerPage="Total"
        currentPage={currentPage}
        totalRows={totalRows}
        rowsPerPage={DEFAULT_PAGE_SIZE}
      />
      <Pagination
        value={currentPage}
        total={Math.ceil(totalRows / DEFAULT_PAGE_SIZE)}
        onChange={onPageChange}
      />
    </Horizontal>
  ) : null;
