import { Container } from '../../design-system/v2';
import { LoginErrorBox } from './LoginErrorBox';

export const AuthError = () => (
  <Container
    fluid
    px={0}
    style={{ height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
  >
    <LoginErrorBox />
  </Container>
);
