import { useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../../design-system/v2/core/data-display/table/util';
import { BaseAPIFilter, Operator, WorkflowRunStatus } from '../../../../generated/api';
import { useGetWorkflowRunsQuery } from '../../../../queries/workflows/list/list';
import { WorkflowRunsListWrapper } from './WorkflowRunsList.wrapper';
import { WorkflowRunTabs } from './WorkflowRunsListTabs';

interface WorkflowRunsListContainerProps {
  workflowId: string;
}

export const WorkflowRunsListContainer = ({
  workflowId,
}: WorkflowRunsListContainerProps): JSX.Element => {
  const [currentPage, setCurrentPage] = useState(1);
  const [activeTab, setActiveTab] = useState(WorkflowRunTabs.ALL);

  const upcomingRunsFilter: BaseAPIFilter = {
    field: 'status',
    operator: Operator.In,
    value: WorkflowRunStatus.Scheduled,
  };
  const runTabFilter: BaseAPIFilter = {
    field: 'status',
    operator: Operator.NotIn,
    value: WorkflowRunStatus.Scheduled,
  };

  const filters = activeTab === WorkflowRunTabs.UPCOMING ? [upcomingRunsFilter] : [runTabFilter];
  // For runs in "Runs" tab sort using startDate, and for runs in upcoming tab use createDate
  const sortKey = activeTab === WorkflowRunTabs.UPCOMING ? 'scheduledTime' : 'startDate';

  const { data, isError, isFetching } = useGetWorkflowRunsQuery({
    workflowId: workflowId,
    start: (currentPage - 1) * DEFAULT_PAGE_SIZE,
    limit: DEFAULT_PAGE_SIZE,
    sortKey: sortKey,
    filters,
  });

  const handlePageNumberChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleTabChange = (tabId: WorkflowRunTabs) => {
    setCurrentPage(1);
    setActiveTab(tabId);
  };

  return (
    <WorkflowRunsListWrapper
      workflowId={workflowId}
      isLoading={isFetching}
      isError={isError}
      workflowRuns={data?.response ?? []}
      currentPage={currentPage}
      onPageChange={handlePageNumberChange}
      totalRows={data?.numRecords ?? 0}
      activeTab={activeTab}
      onTabChange={handleTabChange}
    />
  );
};
