import { Box, Card, Horizontal, ScrollArea, Text, Vertical } from '../../../../design-system/v2';
import { OperatorIcon } from '../../../workspace/studio-home/workflow/OperatorIcon';
import { OperatorRichTextEditor } from './OperatorRichTextEditor';

interface OperationDescriptionCardProps {
  iconUrl: string;
  title: string;
  description: string;
  example: string;
}

export const OperatorDescriptionCard = ({
  iconUrl,
  title,
  description,
  example,
}: OperationDescriptionCardProps) => (
  <Card pl="24px" py="24px" bg="dark.6" sx={theme => ({ borderRadius: theme.spacing.sm })}>
    <ScrollArea.Autosize mah="calc(100vh - 140px)" maw={400} offsetScrollbars>
      <Vertical>
        <Horizontal noWrap>
          <Box w={32} h={32}>
            <OperatorIcon iconUrl={iconUrl} />
          </Box>
          <Text color="white.0" variant="subTitle01">
            {title}
          </Text>
        </Horizontal>
        {example.length === 0 ? (
          <Text color="gray.4" variant="small01">
            {description}
          </Text>
        ) : (
          <OperatorRichTextEditor example={example} />
        )}
      </Vertical>
    </ScrollArea.Autosize>
  </Card>
);
