import { useMediaQuery } from '@mantine/hooks';
import { MantineColor } from '@mantine/styles';
import { ReactNode } from 'react';
import {
  Box,
  Button,
  Horizontal,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../../../design-system/v2';
import { WorkflowRunTabs } from './WorkflowRunsListTabs';

export interface WorkflowListItemProps {
  icon: ReactNode;
  title: ReactNode;
  workflowRunId: string;
  onWorkflowRunDetailsClick: (id: string) => void;
  subTitle?: ReactNode;
  info?: ReactNode;
  titleColor?: MantineColor;
  executionType?: string;
  activeTab: WorkflowRunTabs;
}

export const WorkflowRunListItem = ({
  icon,
  title,
  workflowRunId,
  onWorkflowRunDetailsClick,
  subTitle = '',
  titleColor = 'gray.6',
  executionType = '',
  info = '',
  activeTab,
}: WorkflowListItemProps) => {
  const theme = useMarkovTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  if (isSmallScreen) {
    return (
      <Vertical>
        <Horizontal w="100%" align="start" noWrap>
          <Box pt="xs">{icon}</Box>
          <Vertical w="100%" spacing="xs">
            <Horizontal noWrap>
              <Text variant="subTitle02">{title}</Text>
              {activeTab === WorkflowRunTabs.UPCOMING ? (
                <></>
              ) : (
                <Text variant="small02" color="gray.6">
                  {executionType}
                </Text>
              )}
            </Horizontal>
            <Horizontal w="100%" spacing="xxl" position="apart" align="end" noWrap>
              <Text variant="small02" color={titleColor}>
                {subTitle}
              </Text>
              {info}
            </Horizontal>
          </Vertical>
        </Horizontal>
        {activeTab === WorkflowRunTabs.UPCOMING ? (
          <></>
        ) : (
          <Button w="fit-content" onClick={() => onWorkflowRunDetailsClick(workflowRunId)}>
            View details
          </Button>
        )}
      </Vertical>
    );
  }

  return (
    <Vertical>
      <Horizontal w="100%" align="start" noWrap>
        <Box pt="xs">{icon}</Box>
        <Vertical w="100%" spacing="xs">
          <Text variant="subTitle02">{title}</Text>
          <Horizontal w="100%" spacing="xxl" position="apart" align="end" noWrap>
            <Horizontal spacing={6} align="end" noWrap>
              <Text variant="small02" color={titleColor}>
                {subTitle}
              </Text>
              <Text variant="subTitle01" color="gray.7">
                .
              </Text>
              {activeTab === WorkflowRunTabs.UPCOMING ? (
                <></>
              ) : (
                <Text variant="small02" color="gray.6">
                  {executionType}
                </Text>
              )}
            </Horizontal>
            {info}
          </Horizontal>
        </Vertical>
      </Horizontal>
      {activeTab === WorkflowRunTabs.UPCOMING ? (
        <></>
      ) : (
        <Button w="fit-content" onClick={() => onWorkflowRunDetailsClick(workflowRunId)}>
          View details
        </Button>
      )}
    </Vertical>
  );
};
