import { RichTextEditor } from '@mantine/tiptap';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useMarkovTheme } from '../../../../design-system/v2';
import { LineHeight } from '../../../../design-system/v2/core/typography/constants';
import { Paragraph } from '../../../../design-system/v2/rich-text-editor/extensions/paragraph/Paragraph';
import { SupportAttributes } from '../../../../design-system/v2/rich-text-editor/extensions/support-attributes/extension';

interface OperatorRichTextEditorProps {
  example: string;
}

export const OperatorRichTextEditor = ({ example }: OperatorRichTextEditorProps): JSX.Element => {
  const theme = useMarkovTheme();
  const editor = useEditor({
    extensions: [
      StarterKit,
      Table,
      TableRow,
      TableHeader,
      TableCell,
      Paragraph,
      SupportAttributes.configure({
        types: ['tableRow', 'tableHeader', 'tableCell', 'paragraph'],
        attributes: { class: null },
      }),
    ],
    content: example,
  });

  return (
    <RichTextEditor
      styles={{
        root: {
          border: 0,
          margin: 0,
        },
        content: {
          padding: 0,
          background: theme.colors.dark[6],
          // border: `1px solid ${theme.colors.dark[6]}`,
          '& table': {
            borderCollapse: 'collapse',
            width: '100%',
            marginTop: '8px',
            border: `1px solid ${theme.colors.gray[7]}`,
            padding: '1px',
          },
          '& th': {
            textAlign: 'left',
            padding: 0,
            border: `1px solid ${theme.colors.gray[7]}`,
            color: theme.colors.gray[5],
            fontSize: '11px',
            lineHeight: '16px',
            background: theme.colors.dark[4],
          },
          '& td p': {
            marginBottom: '2px',
            fontSize: '12px',
          },
          '& td': {
            textAlign: 'left',
            padding: '1px',
            border: `1px solid ${theme.colors.gray[7]}`,
            color: theme.colors.gray[5],
            lineHeight: '16px',
            fontSize: '11px',
          },
          '& h5': {
            color: theme.colors.gray[2],
            marginBottom: '2px',
            fontSize: theme.fontSizes.md,
            fontWeight: 400,
            lineHeight: '24px',
          },
          '& p': {
            marginBottom: '8px',
            color: theme.colors.gray[2],
            fontSize: theme.fontSizes.xs,
            fontWeight: 300,
            lineHeight: LineHeight.SM,
          },
          '& .add-shading': {
            backgroundColor: theme.colors.green[8],
            color: theme.colors.gray[8],
          },
          '& .remove-shading': {
            backgroundColor: theme.colors.red[8],
            color: theme.colors.gray[8],
          },
        },
      }}
      editor={editor}
    >
      <RichTextEditor.Content />
    </RichTextEditor>
  );
};
