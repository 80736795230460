import { useNavigate } from 'react-router-dom';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { WorkspaceMembershipRoles, WorkspaceWithMemberDetails } from '../../../generated/api';
import { useAbsoluteRoutes } from '../../../router/hooks';
import { WorkspaceItem } from './WorkspaceItem';

interface WorkspaceSwitcherProps {
  workspaceList: WorkspaceWithMemberDetails[];
  onClose: () => void;
}

export const WorkspaceSwitcher = ({
  workspaceList,
  onClose,
}: WorkspaceSwitcherProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();

  const navigate = useNavigate();
  const { getWorkspaceRoute } = useAbsoluteRoutes();

  const handleWorkspaceNavigation = (itemWorkspaceId: string) => (): void => {
    const workspaceRoute = getWorkspaceRoute(itemWorkspaceId);
    navigate(workspaceRoute);
    onClose();
  };

  return (
    <>
      {workspaceList.map(item => (
        <WorkspaceItem
          key={item.workspaceId}
          currentWorkspace={item.workspaceId === workspaceId}
          workspaceName={item.workspaceName}
          ownerUser={item.members?.workspaceMembers.find(
            member => member.workspaceMembershipRole === WorkspaceMembershipRoles.Owner,
          )}
          onClick={handleWorkspaceNavigation(item.workspaceId)}
        />
      ))}
    </>
  );
};
