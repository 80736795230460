import {
  Box,
  Button,
  Card,
  Container,
  Horizontal,
  Text,
  Vertical,
} from '../../../design-system/v2';
import { ArtifactStateType } from '../../../generated/api';
import { useShowSideBar } from '../../../pages/home/WorkspaceShell';
import { useWorkflowTemplateModal } from '../create/templates/useTemplateModal';
import { GRADIENT_BACKGROUND } from '../create/templates/util';
import { WorkflowsListContainer } from '../list/List.container';

export const getHeadingDescriptionFromState = (state?: ArtifactStateType) => {
  switch (state) {
    case ArtifactStateType.Archived:
      return {
        title: 'Archived',
        description:
          'These workflows are no longer active. They are stored in the archive for future reference,',
      };
    default:
      return {
        title: 'Workflows',
        description:
          'These are the workflows that have been finalized, reviewed, and published for active use.',
      };
  }
};

interface WorkflowsHomePageProps {
  state?: ArtifactStateType;
}

export const WorkflowsListHomePage = ({ state }: WorkflowsHomePageProps) => {
  const { open: openTemplatesModal } = useWorkflowTemplateModal();
  useShowSideBar(true);
  const { title } = getHeadingDescriptionFromState(state);

  return (
    <Vertical h="100%" bg="white.0" spacing={0}>
      <Card hoverEffect={false} px={0} py="lg" sx={{ flexShrink: 0 }} bg={GRADIENT_BACKGROUND}>
        <Container h="100%" size="xl">
          <Horizontal position="apart" noWrap>
            <Box>
              <Text variant="heading03" color="gray.8">
                {title}
              </Text>
            </Box>
            <Button variant="primary" onClick={openTemplatesModal}>
              <Text variant="bodyShort02" color="white.0">
                + Create new
              </Text>
            </Button>
          </Horizontal>
        </Container>
      </Card>
      <Box h="100%">
        <Container h="100%" size="xl" pt="xl">
          <WorkflowsListContainer state={state} />
        </Container>
      </Box>
    </Vertical>
  );
};
