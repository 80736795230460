import { PropsWithChildren, ReactNode } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import { Horizontal, LinkButton, Skeleton, Text, Vertical } from '../../../../design-system/v2';
import { MoreInfoPopover } from './MoreInfoPopover';

const Separator = (
  <Text variant="subTitle02" color="gray.8">
    /
  </Text>
);
const SkeletonLoader = <Skeleton w={150} h={25} />;

const getWrappedTitle = (title: ReactNode) => {
  const type = typeof title;
  if (type === 'string' || type === 'boolean' || type === 'number') {
    return (
      <Text variant="subTitle02" color="gray.8" aria-label={title?.toString()} tabIndex={0}>
        {title}
      </Text>
    );
  } else {
    return title;
  }
};

export interface PageInfoProps {
  parentTitle: string;
  title: ReactNode;
  additionalTitleElement?: ReactNode;
  backButtonRoute: string;
  isMetadataLoading?: boolean;
  onBackClick?: () => void;
  moreInfo?: ReactNode;
}

export const PageInfo = ({
  parentTitle,
  title,
  additionalTitleElement,
  backButtonRoute,
  isMetadataLoading = false,
  onBackClick,
  moreInfo,
}: PropsWithChildren<PageInfoProps>) => {
  const [searchParams] = useSearchParams();

  const paramsStr = createSearchParams(searchParams).toString();
  const backRouteWithSearchParams = `${backButtonRoute}?${paramsStr}`;

  const WrappedTitle = getWrappedTitle(title);
  const WrappedTitleWithSkeleton = isMetadataLoading ? SkeletonLoader : WrappedTitle;

  return (
    <Vertical spacing={0} pl="sm" align="start">
      <Horizontal noWrap spacing="xs" align="center">
        <LinkButton
          aria-label={`Back to ${parentTitle}`}
          variant="subtle"
          to={backRouteWithSearchParams}
          onClick={onBackClick}
          p={0}
        >
          <Text pl="xs" variant="subTitle02" color="gray.8">
            {parentTitle}
          </Text>
        </LinkButton>
        {Boolean(WrappedTitleWithSkeleton) && Separator}
        {WrappedTitleWithSkeleton}
        {additionalTitleElement}
      </Horizontal>
      <MoreInfoPopover>{moreInfo}</MoreInfoPopover>
    </Vertical>
  );
};
