import { forwardRef } from 'react';
import { Button, ButtonProps, Image } from '../../../design-system/v2';
import { PaymentSubscriptionType } from '../../../generated/api';
import { PLAN_ICON_WIDTH, subscriptionPlanDetails } from '../../subscriptions/util';

export const UpgradeButton = forwardRef<HTMLButtonElement, ButtonProps>(({ onClick }, ref) => {
  const iconUrl = subscriptionPlanDetails[PaymentSubscriptionType.FreemiumMonthly].iconUrl;

  return (
    <Button
      ref={ref}
      onClick={onClick}
      variant="light"
      color="yellow"
      leftIcon={<Image src={iconUrl} width={PLAN_ICON_WIDTH} />}
      sx={theme => ({ ...theme.fn.hover({ background: theme.colors.yellow[1] }) })}
    >
      Upgrade
    </Button>
  );
});

UpgradeButton.displayName = 'UpgradeButton';
