import { MARKOV_LOGO_BLACK_TEXT_URL } from '../../components/common/MarkovLogo';
import { useAuthLogin } from '../../contexts/auth-provider/Auth';
import {
  Box,
  Button,
  Flex,
  Horizontal,
  Image,
  LinkAnchor,
  Text,
  useMarkovTheme,
  Vertical,
} from '../../design-system/v2';

const LOGIN_ERROR_ILLUSTRATION_URL =
  'https://ik.imagekit.io/markovml/sign_in/markov-logout-error_VVNGWCDoU.svg?';

export const LoginErrorBox = (): JSX.Element => {
  const { handleLoginRedirect } = useAuthLogin();
  const theme = useMarkovTheme();

  return (
    <Box w={420} h={520} sx={{ borderRadius: '20px', boxShadow: theme.shadows.lg }} bg="white.0">
      <Vertical p={40} align="center" spacing="lg">
        <Flex align="center">
          <Image src={MARKOV_LOGO_BLACK_TEXT_URL} />
        </Flex>
        <Box pt={48}>
          <Image src={LOGIN_ERROR_ILLUSTRATION_URL} />
        </Box>
        <Text variant="subTitle01">Looks like you have been logged out</Text>
        <Text variant="bodyShort01">Please try logging in again</Text>
        <Button variant="primary" onClick={handleLoginRedirect} mt="md">
          Log In
        </Button>
        <Horizontal spacing="xs">
          <Text variant="bodyLong02">Need Help?</Text>
          <LinkAnchor
            external
            href="mailto:support@markovml.com"
            sx={theme => ({
              textDecoration: 'underline',
              color: theme.colors.blue[6],
            })}
          >
            Contact Support
          </LinkAnchor>
        </Horizontal>
      </Vertical>
    </Box>
  );
};
