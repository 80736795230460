import {
  Badge,
  Flex,
  Horizontal,
  Text,
  Tooltip,
  useMarkovTheme,
  Vertical,
} from '../../../../design-system/v2/';
import { ICellRendererParams } from '../../../../design-system/v2/core/data-display/table/ag-grid';
import { Workflow, WorkflowRunStatus } from '../../../../generated/api';
import { formatDateTime, toPlural } from '../../../../lib/ui';
import { getWorkflowRunIcon } from '../../detail/runs/util';

import { WorkflowRunStatusBadge } from '../../detail/runs/WorkflowRunStatusBadge';

export const RunsStatusRenderer = ({ data }: ICellRendererParams<Workflow>) => {
  const theme = useMarkovTheme();

  if (!data?.totalRuns || !data?.recentRuns || data.recentRuns.length === 0) {
    return (
      <Flex h="100%" w="100%" align="center">
        <Text color="gray.05"> -- -- </Text>
      </Flex>
    );
  }

  const runsToShow = 5;
  const extraRuns = data.totalRuns > runsToShow ? data.totalRuns - runsToShow : 0;

  const statusElements = data.recentRuns.slice(0, runsToShow).map((run, index) => (
    <Tooltip
      key={index}
      label={
        <Vertical>
          <Horizontal>
            <Text>Status:</Text>
            <WorkflowRunStatusBadge status={run.status} />
          </Horizontal>
          <Text>Started: {formatDateTime(run.startDate ?? '')}</Text>
          <Text>
            End:{' '}
            {run.status === WorkflowRunStatus.CreationFailed ||
            run.status === WorkflowRunStatus.ExecutionFailed
              ? '--'
              : formatDateTime(run.endDate ?? '')}
          </Text>
        </Vertical>
      }
      withArrow
      withinPortal
    >
      {getWorkflowRunIcon(run.status, theme)}
    </Tooltip>
  ));

  return (
    <Flex h="100%" w="100%" align="center">
      <Vertical spacing={0}>
        <Text variant="small03">{toPlural(data.totalRuns, 'Run')}</Text>
        <Horizontal spacing="xs">
          {statusElements}
          {extraRuns > 0 && (
            <Badge color="gray.3" bg="gray.1" variant="outline">
              <Text color="gray.6" variant="small03">
                +{extraRuns}
              </Text>
            </Badge>
          )}
        </Horizontal>
      </Vertical>
    </Flex>
  );
};
