import first from 'lodash/first';
import { useState } from 'react';
import { useCreateWorkflow } from '../../../../../contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '../../../../../contexts/workflows/WorkflowRunContext';
import { Alert, Horizontal, Modal, Text, notifications } from '../../../../../design-system/v2';
import { OperatorModel } from '../../../../../generated/api';
import { useGetWorkflowDAGQuery } from '../../../../../queries/workflows/dag';
import { transformEdgeToWorkflowEdge } from '../../../edges/util';
import { WorkflowNode, findNodesAfter, transformNodeToWorkflowNode } from '../../../nodes/utils';
import { Mode, findSourceNodes } from '../../utils';
import { NodeFormView } from './NodeFormView';
import { OperatorExampleModal } from './OperatorExampleModal';
import { FormDataTypes } from './util';

interface FormModalProps {
  id: string;
  isOpen: boolean;
  handleFormClose: () => void;
  operatorModel?: OperatorModel;
  configuration?: Record<string, FormDataTypes>;
}

export const FormModal = ({
  id,
  isOpen,
  handleFormClose,
  operatorModel,
  configuration,
}: FormModalProps): JSX.Element => {
  const { workflowId, nodes, setNodes, edges, saveWorkflowDAG, isSaving } = useCreateWorkflow();
  const { runId } = useWorkflowRunContext();

  const [data, setData] = useState(configuration);
  const [isFormDirty, setIsFormDirty] = useState(false);

  const handleFormSubmit = async (formState: Record<string, FormDataTypes>) => {
    const newNodes = nodes.map<WorkflowNode>(node => {
      if (node.id === id) {
        return {
          ...node,
          data: {
            ...node.data,
            configuration: formState ?? {},
          },
        };
      }
      return node;
    });

    const nodesAfter = findNodesAfter(id, edges);

    saveWorkflowDAG(newNodes, edges, true, nodesAfter);
    setNodes(newNodes);
    notifications.success('Changes saved successfully');
  };

  const handleClose = () => {
    setData(configuration);
    handleFormClose();
  };

  const prevNodeId = first(findSourceNodes({ nodes, edges }, id));
  const isExampleAvailable = !!operatorModel?.example;

  return (
    <Modal
      withinPortal
      opened={isOpen}
      onClose={handleClose}
      size="1480px"
      padding={0}
      title={
        <Horizontal spacing={0} px="24px" pt="lg" pb="sm">
          <Text variant="heading04">{operatorModel?.name}</Text>
          {isExampleAvailable && <OperatorExampleModal operator={operatorModel} />}
        </Horizontal>
      }
      closeButtonProps={{
        mr: '24px',
      }}
    >
      {operatorModel ? (
        <NodeFormView
          workflowId={workflowId}
          runId={runId}
          nodeId={id}
          operatorModel={operatorModel}
          data={data}
          setData={setData}
          isFormDirty={isFormDirty}
          setIsFormDirty={setIsFormDirty}
          initialState={configuration}
          onFormSubmit={handleFormSubmit}
          prevNodeId={prevNodeId}
          isFormSaving={isSaving}
        />
      ) : (
        <Alert>Could not find operator</Alert>
      )}
    </Modal>
  );
};

interface ReadOnlyFormModalProps {
  id: string;
  workflowId: string;
  operatorModel?: OperatorModel;
  configuration?: Record<string, FormDataTypes>;
  isOpen: boolean;
  handleClose: () => void;
  isDebugMode: boolean;
}

export const ReadOnlyFormModal = ({
  id,
  workflowId,
  operatorModel,
  configuration,
  isOpen,
  handleClose,
  isDebugMode,
}: ReadOnlyFormModalProps): JSX.Element => {
  const { data } = useGetWorkflowDAGQuery(workflowId);
  const [formData, setFormData] = useState(configuration);

  const prevNodeId =
    operatorModel && data
      ? first(
          findSourceNodes(
            {
              nodes: data.nodes.map(n => transformNodeToWorkflowNode(n, [operatorModel])),
              edges: data.edges.map(edge => transformEdgeToWorkflowEdge(edge)),
            },
            id,
          ),
        )
      : undefined;

  return (
    <Modal
      withinPortal
      opened={isOpen}
      onClose={handleClose}
      size="1480px"
      padding={0}
      title={
        <Horizontal spacing={0} px="24px" pt="lg" pb="xs">
          <Text variant="heading04">{operatorModel?.name}</Text>
        </Horizontal>
      }
      closeButtonProps={{
        mr: '24px',
      }}
    >
      {operatorModel && data ? (
        <NodeFormView
          workflowId={workflowId}
          nodeId={id}
          data={formData}
          setData={setFormData}
          operatorModel={operatorModel}
          initialState={configuration}
          viewOnly
          prevNodeId={prevNodeId}
          mode={isDebugMode ? Mode.Debug : Mode.Build}
        />
      ) : (
        <Alert>Could not find operator</Alert>
      )}
    </Modal>
  );
};
