import { RouteProps, Routes } from 'react-router-dom';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const Datasets = lazyWithRetry(() => import('../pages/Datasets'));
const DatasetComparison = lazyWithRetry(() => import('../pages/DatasetComparison'));
const DatasetDetailsPage = lazyWithRetry(() => import('../pages/DatasetDetails'));
const DatasetRegistrationPage = lazyWithRetry(() => import('../pages/DatasetRegistration'));
const ManualRelabelingPage = lazyWithRetry(() => import('../pages/Relabeling/ManualRelabeling'));
const RuleBasedRelabelingPage = lazyWithRetry(
  () => import('../pages/Relabeling/RuleBasedRelabeling'),
);
const SubsetRelabelingPage = lazyWithRetry(() => import('../pages/Relabeling/SubsetRelabeling'));
const ChatWithDatasetPage = lazyWithRetry(
  () => import('../pages/dataset/chat-with-dataset/ChatWithDataset'),
);

const datasetRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Datasets />,
  },
  {
    path: getRoute(AppRoutes.DATASETS_DETAILS),
    element: <DatasetDetailsPage />,
  },
  {
    path: `${getRoute(AppRoutes.DATASETS_DETAILS)}/${getRoute(AppRoutes.MANUAL_RELABELING)}`,
    element: <ManualRelabelingPage />,
  },
  {
    path: `${getRoute(AppRoutes.DATASETS_DETAILS)}/${getRoute(AppRoutes.RULE_BASED_RELABELING)}`,
    element: <RuleBasedRelabelingPage />,
  },
  {
    path: `${getRoute(AppRoutes.DATASETS_DETAILS)}/${getRoute(AppRoutes.SUBSET_RELABELING)}`,
    element: <SubsetRelabelingPage />,
  },
  {
    path: `${getRoute(AppRoutes.DATASETS_DETAILS)}/${getRoute(AppRoutes.CHAT_WITH_DATASET)}`,
    element: <ChatWithDatasetPage />,
  },
  {
    path: getRoute(AppRoutes.COMPARE_DATASETS),
    element: <DatasetComparison />,
  },
  {
    path: getRoute(AppRoutes.DATASETS_REGISTRATION),
    element: <DatasetRegistrationPage />,
  },
];

export const DatasetsRouter = (): JSX.Element => (
  <Routes>{datasetRoutes.map(renderAuthRoute)}</Routes>
);
