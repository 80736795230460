import first from 'lodash/first';
import { useEffect, useRef, useState } from 'react';
import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { WorkflowRunContextProvider } from '../../../../contexts/workflows/WorkflowRunContext';
import {
  Box,
  Button,
  Center,
  Grid,
  Overlay,
  ScrollArea,
  Text,
  Vertical,
  openModal,
} from '../../../../design-system/v2';
import { OperatorModel, TemplateModel, UpsertDAGRequest } from '../../../../generated/api';
import { sendAnalytics } from '../../../../initializers/analytics';
import { formatDateTime } from '../../../../lib/ui';
import { OperatorIcon } from '../../../workspace/studio-home/workflow/OperatorIcon';
import { WorkflowViewer } from '../../detail/viewer/WorkflowViewer';
import { GRADIENT_BACKGROUND } from './util';

const SCROLLAREA_MAX_HEIGHT = 188;

interface TemplateFlowModalProps {
  templateData: TemplateModel;
  operatorList: OperatorModel[];
  handleAddWorkflow?: (templateId?: string, workflowName?: string) => void;
}

const TemplateFlowModal = ({
  templateData,
  operatorList,
  handleAddWorkflow,
}: TemplateFlowModalProps) => {
  const { workspaceId } = useAppMetadata();
  const [textHeight, setTextHeight] = useState(0);
  const textRef = useRef<HTMLDivElement>(null);

  const handleButtonClick = () => {
    if (handleAddWorkflow) {
      sendAnalytics(
        workflowEvents.create.fromTemplate({
          workspaceId,
          templateId: templateData.templateId,
        }),
      );
      handleAddWorkflow(
        templateData.templateId,
        `${templateData.name}-${formatDateTime(new Date())}`,
      );
    }
  };

  useEffect(() => {
    if (textRef.current) {
      const height = textRef.current.offsetHeight;
      setTextHeight(height);
    }
  }, [templateData.description]);

  const firstKeyOperatorId = first(templateData.keyOperators);
  const keyOperator = operatorList.find(operator => operator.operatorId === firstKeyOperatorId);

  return (
    <Grid h="100%" p="xl" gutter="xl">
      <Grid.Col span="content">
        <Vertical w={300}>
          <Center h={48} w={48} bg="gray.1" mb="xs" sx={{ borderRadius: '50%' }}>
            <OperatorIcon iconUrl={templateData.iconUrl} />
          </Center>
          <Text variant="heading02" color="gray.9">
            {templateData.name}
          </Text>
          <ScrollArea.Autosize mah={SCROLLAREA_MAX_HEIGHT}>
            <Text
              variant="bodyLong01"
              color="gray.7"
              pb={textHeight > SCROLLAREA_MAX_HEIGHT ? 32 : 0}
              ref={textRef}
            >
              {templateData.description}
            </Text>

            {textHeight > SCROLLAREA_MAX_HEIGHT && (
              <Overlay
                gradient=" linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, rgba(255, 255, 255, 0.00) 45%, #FFF 100%)"
                style={{ zIndex: 1, pointerEvents: 'none' }}
              />
            )}
          </ScrollArea.Autosize>
          {handleAddWorkflow && (
            <Button
              variant="primary"
              w={184}
              mt="md"
              h="100%"
              color="dark.4"
              onClick={handleButtonClick}
            >
              <Text variant="subTitle02" color="white.0" py="md">
                Use this template
              </Text>
            </Button>
          )}
        </Vertical>
      </Grid.Col>
      <Grid.Col span="auto">
        <Box bg="gray.1" h={412}>
          <WorkflowRunContextProvider workflowId="">
            <WorkflowViewer
              dag={templateData.dag as UpsertDAGRequest}
              operatorsList={operatorList}
              renderNodeActions
            />
          </WorkflowRunContextProvider>
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export const useTemplateFlowModal = () => {
  const open = (
    templateData: TemplateModel,
    operatorList: OperatorModel[],
    handleAddWorkflow?: (templateId?: string, workflowName?: string) => void,
  ) =>
    openModal({
      size: '1000px',
      withCloseButton: false,
      styles: { header: { background: GRADIENT_BACKGROUND } },
      children: (
        <TemplateFlowModal
          templateData={templateData}
          operatorList={operatorList}
          handleAddWorkflow={handleAddWorkflow}
        />
      ),
    });
  return { open };
};
