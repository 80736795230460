import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAppMetadata } from '../../contexts/app-metadata/AppMetadata';
import { UpsertDAGRequest } from '../../generated/api';
import { workflowApi } from '../../lib/api';
import { workflowsQueryKeys } from './list/list';

const builderKeys = {
  all: (workspaceId: string) => ['workflow-builder', workspaceId],
  create: (workspaceId: string) => builderKeys.all(workspaceId),
  save: (workspaceId: string, workflowId: string) => [...builderKeys.all(workspaceId), workflowId],
};

export const useCreateWorkflowMutation = () => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    builderKeys.create(workspaceId),
    ({ workflowName, templateId }: { workflowName?: string; templateId?: string }) =>
      workflowApi.createWorkflowV1(workspaceId, workflowName, templateId),
    {
      onSuccess: () => queryClient.invalidateQueries({ queryKey: workflowsQueryKeys.all }),
    },
  );
};

export const useSaveWorkflowGraphMutation = (workflowId: string) => {
  const { workspaceId } = useAppMetadata();

  return useMutation((req: UpsertDAGRequest) =>
    workflowApi.updateDAGsV1(workspaceId, workflowId, req),
  );
};
