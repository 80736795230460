import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const Snippets = lazyWithRetry(() => import('../pages/Snippets/Snippets'));
const SnippetDetail = lazyWithRetry(() => import('../pages/Snippets/SnippetDetail'));

const snippetRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Snippets />,
  },
  {
    path: getRoute(AppRoutes.SNIPPET_DETAIL),
    element: <SnippetDetail />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const SnippetsRouter = (): JSX.Element => (
  <Routes>{snippetRoutes.map(renderAuthRoute)}</Routes>
);
