import { Vertical } from '../../../../../design-system/v2';
import { useGetWorkflowDetailsQuery } from '../../../../../queries/workflows/detail/detail';
import {
  DEFAULT_ROW_HEIGHT,
  DEFAULT_TABLE_MENU_WIDTH,
} from '../../../../datasets-table/v2/cell-renderers/util';
import { TableActionMenu } from '../../../../table-action-menu/TableActionMenu';
import { useWorkflowActions } from './use-workflow-actions';

interface AdditionalActionsContainerProps {
  workflowId: string;
}

export const AdditionalActionsContainer = ({ workflowId }: AdditionalActionsContainerProps) => {
  const { data: workflow } = useGetWorkflowDetailsQuery(workflowId);

  const actions = useWorkflowActions(workflow);

  return (
    <Vertical justify="center" h={DEFAULT_ROW_HEIGHT}>
      <TableActionMenu actions={actions} width={DEFAULT_TABLE_MENU_WIDTH} />
    </Vertical>
  );
};
