import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const Models = lazyWithRetry(() => import('../pages/Models/Models'));
const ModelRegistryPage = lazyWithRetry(() => import('../pages/Models/ModelRegistry'));
const ModelRegistryDetailsPage = lazyWithRetry(
  () => import('../pages/Models/ModelRegistryDetails'),
);

// More workspace-level model routes are anticipated (deleted models, possibly model comparison)
const modelRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Navigate replace to={getRoute(AppRoutes.MODEL_REGISTRY)} />,
  },
  {
    path: getRoute(AppRoutes.MODEL_REGISTRY_DETAILS),
    element: <ModelRegistryDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.MODEL_REGISTRY),
    element: <ModelRegistryPage />,
  },
  {
    path: '/all',
    element: <Models />,
  },
];

const modelErrorRoutes: RouteProps[] = [
  {
    path: getRoute(AppRoutes.MODEL_APP_DETAILS),
    element: <ModelRegistryDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const ModelsRouter = (): JSX.Element => {
  const finalModelRoutes = [...modelRoutes, ...modelErrorRoutes];

  return <Routes>{finalModelRoutes.map(renderAuthRoute)}</Routes>;
};
