import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import { ScrollArea, Vertical } from '../../../../design-system/v2';
import { OperatorModel, TemplateModel } from '../../../../generated/api';
import { sendAnalytics } from '../../../../initializers/analytics';
import { formatDateTime } from '../../../../lib/ui';
import { TemplateCard } from './TemplateCard';
import { useTemplateFlowModal } from './useTemplateFlowModal';

interface TemplateListProps {
  templates: TemplateModel[];
  operatorList: OperatorModel[];
  handleAddWorkflow: (templateId?: string, workflowName?: string) => void;
}

export const TemplateList = ({ templates, handleAddWorkflow, operatorList }: TemplateListProps) => {
  const { workspaceId } = useAppMetadata();
  const { open: openTemplateFlowModal } = useTemplateFlowModal();

  const handleFlowButtonClick = (templateData: TemplateModel) => {
    openTemplateFlowModal(templateData, operatorList ?? []);
  };

  const handleCloneClick = (template: TemplateModel) => {
    sendAnalytics(
      workflowEvents.create.fromTemplate({ workspaceId, templateId: template.templateId }),
    );
    handleAddWorkflow(template.templateId, `${template.name}-${formatDateTime(new Date())}`);
  };

  return (
    <ScrollArea.Autosize p="sm" h="calc(100% - 60px)">
      <Vertical spacing="lg">
        {templates.map(template => (
          <TemplateCard
            key={template.templateId}
            template={template}
            onFlowClick={handleFlowButtonClick}
            onCloneClick={handleCloneClick}
          />
        ))}
      </Vertical>
    </ScrollArea.Autosize>
  );
};
