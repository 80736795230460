import { Tabs } from '../../../../design-system/v2';

export enum WorkflowRunTabs {
  ALL = 'Runs',
  UPCOMING = 'Upcoming',
}
const TABS = [
  {
    id: WorkflowRunTabs.ALL,
    label: 'Runs',
  },
  {
    id: WorkflowRunTabs.UPCOMING,
    label: 'Upcoming',
  },
];

export interface WorkflowRunsListTabsProps {
  activeTab: WorkflowRunTabs;
  onTabChange: (tabId: WorkflowRunTabs) => void;
}

export const WorkflowRunsListTabs = ({ activeTab, onTabChange }: WorkflowRunsListTabsProps) => (
  <Tabs value={activeTab} onTabChange={onTabChange} px="lg">
    <Tabs.List sx={{ borderBottom: 'none' }}>
      {TABS.map(tab => (
        <Tabs.Tab key={tab.id} value={tab.id}>
          {tab.label}
        </Tabs.Tab>
      ))}
    </Tabs.List>
  </Tabs>
);
