import {
  WorkflowsAddOperatorClicked,
  WorkflowsAddOperatorClickedProperties,
  WorkflowsAddOperatorDropped,
  WorkflowsAddOperatorDroppedProperties,
  WorkflowsCloneWorkflowClicked,
  WorkflowsCloneWorkflowClickedProperties,
  WorkflowsCreateBlankWorkflowClicked,
  WorkflowsCreateBlankWorkflowClickedProperties,
  WorkflowsCreateWorkflowFromTemplateClicked,
  WorkflowsCreateWorkflowFromTemplateClickedProperties,
  WorkflowsDebugRunClicked,
  WorkflowsDebugRunClickedProperties,
  WorkflowsLinkNodesWithEdge,
  WorkflowsLinkNodesWithEdgeProperties,
  WorkflowsNodeDeleteBtnClicked,
  WorkflowsNodeDeleteBtnClickedProperties,
  WorkflowsNodeEditBtnClicked,
  WorkflowsNodeEditBtnClickedProperties,
  WorkflowsNodeLogsClicked,
  WorkflowsNodeLogsClickedProperties,
  WorkflowsNodeOutputPreviewClicked,
  WorkflowsNodeOutputPreviewClickedProperties,
  WorkflowsOperatorExampleHover,
  WorkflowsOperatorExampleHoverProperties,
  WorkflowsOperatorNodeClicked,
  WorkflowsOperatorNodeClickedProperties,
  WorkflowsOperatorNodesListSearch,
  WorkflowsOperatorNodesListSearchProperties,
  WorkflowsOperatorNodesListTabClicked,
  WorkflowsOperatorNodesListTabClickedProperties,
  WorkflowsPublishWorkflowClicked,
  WorkflowsPublishWorkflowClickedProperties,
  WorkflowsRunPublishedWorkflowClicked,
  WorkflowsRunPublishedWorkflowClickedProperties,
  WorkflowsViewLastDebugRunClicked,
  WorkflowsViewLastDebugRunClickedProperties,
  WorkflowsWorkflowCreationCancelled,
  WorkflowsWorkflowCreationCancelledProperties,
} from '../ampli';

export const workflowEvents = {
  create: {
    blank: (data: WorkflowsCreateBlankWorkflowClickedProperties) =>
      new WorkflowsCreateBlankWorkflowClicked(data),
    fromTemplate: (data: WorkflowsCreateWorkflowFromTemplateClickedProperties) =>
      new WorkflowsCreateWorkflowFromTemplateClicked(data),
    clone: (data: WorkflowsCloneWorkflowClickedProperties) =>
      new WorkflowsCloneWorkflowClicked(data),
    cancel: (data: WorkflowsWorkflowCreationCancelledProperties) =>
      new WorkflowsWorkflowCreationCancelled(data),
  },
  dag: {
    searchOperators: (data: WorkflowsOperatorNodesListSearchProperties) =>
      new WorkflowsOperatorNodesListSearch(data),
    operatorsTabClicked: (data: WorkflowsOperatorNodesListTabClickedProperties) =>
      new WorkflowsOperatorNodesListTabClicked(data),
    addOperatorDrop: (data: WorkflowsAddOperatorDroppedProperties) =>
      new WorkflowsAddOperatorDropped(data),
    addOperatorClicked: (data: WorkflowsAddOperatorClickedProperties) =>
      new WorkflowsAddOperatorClicked(data),
    hoverOperatorExample: (data: WorkflowsOperatorExampleHoverProperties) =>
      new WorkflowsOperatorExampleHover(data),
    operatorNodeClicked: (data: WorkflowsOperatorNodeClickedProperties) =>
      new WorkflowsOperatorNodeClicked(data),
    editNodeClicked: (data: WorkflowsNodeEditBtnClickedProperties) =>
      new WorkflowsNodeEditBtnClicked(data),
    deleteNodeClicked: (data: WorkflowsNodeDeleteBtnClickedProperties) =>
      new WorkflowsNodeDeleteBtnClicked(data),
    linkNodesWithEdge: (data: WorkflowsLinkNodesWithEdgeProperties) =>
      new WorkflowsLinkNodesWithEdge(data),
  },
  runs: {
    debugRunClicked: (data: WorkflowsDebugRunClickedProperties) =>
      new WorkflowsDebugRunClicked(data),
    viewLastRunClicked: (data: WorkflowsViewLastDebugRunClickedProperties) =>
      new WorkflowsViewLastDebugRunClicked(data),
    nodePreviewClicked: (data: WorkflowsNodeOutputPreviewClickedProperties) =>
      new WorkflowsNodeOutputPreviewClicked(data),
    nodeLogsClicked: (data: WorkflowsNodeLogsClickedProperties) =>
      new WorkflowsNodeLogsClicked(data),
    runPublishedWorkflow: (data: WorkflowsRunPublishedWorkflowClickedProperties) =>
      new WorkflowsRunPublishedWorkflowClicked(data),
  },
  publish: {
    publishClicked: (data: WorkflowsPublishWorkflowClickedProperties) =>
      new WorkflowsPublishWorkflowClicked(data),
  },
};
