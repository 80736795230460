import { Alert } from '../../../design-system/v2';
import { useGetWorkspaceCurrentSubscriptionDetailsQuery } from '../../../queries/account/payment-and-subscription';
import { Subscription } from './Subscription';

export const SubscriptionContainer = () => {
  const {
    isLoading: isSubscriptionLoading,
    isError: isSubscriptionError,
    data: subscriptionDetail,
  } = useGetWorkspaceCurrentSubscriptionDetailsQuery();

  if (isSubscriptionError) {
    return <Alert color="red">Failed to retrieve Subscription Info</Alert>;
  }

  return <Subscription isLoading={isSubscriptionLoading} subscriptionDetail={subscriptionDetail} />;
};
