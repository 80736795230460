import { ArtifactFilterState, ArtifactStateType, ResourceType } from '../../../../generated/api';
import { useWorkflowStateUpdateMutation } from '../../../../queries/workflows/list/list';
import { useArchiveUnarchive } from '../../../archive/use-archive-unarchive';

export const useWorkflowArchiveUnarchive = (
  workflowIds: string[],
  artifactState: ArtifactStateType,
  resetWorkflowSelection?: () => void,
) => {
  const count = workflowIds.length;

  const handleSelectionReset = () => {
    resetWorkflowSelection?.();
  };

  const {
    mutateAsync: archiveUnarchiveWorkflow,
    isError,
    isLoading,
  } = useWorkflowStateUpdateMutation(handleSelectionReset);

  return useArchiveUnarchive(
    count,
    ResourceType.Workflow,
    artifactState === ArtifactStateType.Active || artifactState === ArtifactStateType.Draft
      ? ArtifactFilterState.Active
      : ArtifactFilterState.Archived,
    () =>
      archiveUnarchiveWorkflow({
        workflowIds,
        artifactState:
          artifactState === ArtifactStateType.Active
            ? ArtifactStateType.Archived
            : ArtifactStateType.Active,
      }),
    isError,
    isLoading,
  );
};
