import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../../pages/Unavailable';
import { renderAuthRoute } from '../AuthRouter';
import { AppRoutes, getRoute } from '../constants';
import { lazyWithRetry } from '../util';

const RetailAiPage = lazyWithRetry(() => import('../../pages/AppStore/RetailAI/RetailAi'));
const RetailAiCategoryListPage = lazyWithRetry(
  () => import('../../pages/AppStore/RetailAI/RetailAiCategoryList'),
);
const RetailAiProductListPage = lazyWithRetry(
  () => import('../../pages/AppStore/RetailAI/RetailAiProductList'),
);
const RetailAiDetailsPage = lazyWithRetry(
  () => import('../../pages/AppStore/RetailAI/RetailAiDetails'),
);

const retailAiRoutes: RouteProps[] = [
  {
    path: '/',
    element: <RetailAiPage />,
  },
  {
    path: getRoute(AppRoutes.RETAIL_AI_PROJECT),
    element: <RetailAiCategoryListPage />,
  },
  {
    path: `${getRoute(AppRoutes.RETAIL_AI_PROJECT)}/${getRoute(AppRoutes.RETAIL_AI_CATEGORY)}`,
    element: <RetailAiProductListPage />,
  },
  {
    path: `${getRoute(AppRoutes.RETAIL_AI_PROJECT)}/${getRoute(
      AppRoutes.RETAIL_AI_CATEGORY,
    )}/${getRoute(AppRoutes.RETAIL_AI_DETAILS)}`,
    element: <RetailAiDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const RetailAiRouter = () => <Routes>{retailAiRoutes.map(renderAuthRoute)}</Routes>;
