// When running in Cypress mode, we don't want any pages to be authenticated

import { withAuthenticationRequired } from '@auth0/auth0-react';
import { ErrorBoundary } from '@sentry/react';
import { Suspense } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { Loading } from '../components/common/Loading';
import { FullPageLoader } from '../design-system';
import { ErrorFallback } from '../ErrorBoundary';

// Authentication is enforced for each protected route
export const AuthRoute = ({ element }: RouteProps) => {
  const Component = withAuthenticationRequired(() => <>{element}</>, {
    onRedirecting: () => <FullPageLoader text="Authenticating..." />,
  });
  return window.Cypress ? <>{element}</> : <Component />;
};

// Each auth route is wrapped in a React Suspense instance
export const renderAuthRoute = ({ path, element }: RouteProps): JSX.Element => (
  <Route
    key={path}
    path={path}
    element={
      <ErrorBoundary fallback={ErrorFallback}>
        <Suspense fallback={<Loading />}>
          <AuthRoute path={path} element={element} />
        </Suspense>
      </ErrorBoundary>
    }
  />
);
