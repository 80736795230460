import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import merge from 'lodash/merge';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { notifications } from '../../../design-system/v2';
import {
  AnalysisResultsDownload,
  AnalysisResultsDownloadLinkResponse,
  AnalysisSelection,
  AnalysisStateNames,
  AnalysisTasklet,
  DatasetTasklet,
  FullAnalysesStatus,
  GetAvailableAnalysisTaskletsResponse,
  GetFullAnalysesResponse,
  GetFullAnalysesStatusResponse,
} from '../../../generated/api';
import { logger } from '../../../initializers/logging';
import { datasetApi, fullDataAnalysisApi } from '../../../lib/api';
import { fullDataAnalysisTaskKeys } from '../../queryConstants';
import { responseSelector } from '../../util';

export const useFullDataAnalysisTasksQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetFullAnalysesStatusResponse>, AxiosError, FullAnalysesStatus[]>(
    fullDataAnalysisTaskKeys.listTasks(workspaceId, datasetId),
    () => fullDataAnalysisApi.getDatasetAnalysesStatusV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: responseSelector,
      refetchInterval: 30 * 1000,
    },
  );
};

export const useFullDataAnalysisTaskletsQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<AxiosResponse<GetFullAnalysesResponse>, AxiosError, AnalysisTasklet[]>(
    fullDataAnalysisTaskKeys.listTasklets(workspaceId, datasetId),
    () => fullDataAnalysisApi.getFullAnalysisForDatasetV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: responseSelector,
    },
  );
};

export const useAvailableFullDataAnalysisTaskletsQuery = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<GetAvailableAnalysisTaskletsResponse>,
    AxiosError,
    DatasetTasklet[]
  >(
    fullDataAnalysisTaskKeys.listAvailableTasklets(workspaceId, datasetId),
    () => fullDataAnalysisApi.getAvailableFullDataAnalysisV1(workspaceId, datasetId),
    {
      enabled: Boolean(workspaceId && datasetId),
      select: responseSelector,
    },
  );
};

export const useTriggerFullAnalysisBatchMutation = (datasetId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    (selection: AnalysisSelection[]) =>
      fullDataAnalysisApi.triggerFullAnalysisV1(workspaceId, datasetId, selection),
    {
      onError: () => {
        const errorMsg = 'Error encountered while triggering full data analysis.';
        logger.error(errorMsg);
        notifications.show({
          variant: 'error',
          message: errorMsg,
        });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(fullDataAnalysisTaskKeys.listTasks(workspaceId, datasetId));
      },
    },
  );
};

export const useDownloadFullAnalysisQuery = (
  datasetId: string,
  taskletId: string,
  segmentType: string,
) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<AnalysisResultsDownloadLinkResponse>,
    AxiosError,
    AnalysisResultsDownload
  >(
    fullDataAnalysisTaskKeys.results(workspaceId, datasetId, taskletId, segmentType),
    () =>
      fullDataAnalysisApi.downloadLinkForFullDataAnalysisResultsV1(
        workspaceId,
        datasetId,
        taskletId,
        segmentType,
      ),
    {
      enabled: false,
      select: responseSelector,
    },
  );
};

export const useRerunFullAnalysisTaskMutation = (datasetId: string, taskId: string) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();
  const fullAnalysisTaskListQueryKey = fullDataAnalysisTaskKeys.listTasks(workspaceId, datasetId);

  return useMutation(
    () => datasetApi.workspaceAnalysisTaskRetriggerV1(workspaceId, datasetId, taskId),
    {
      onMutate: async () => {
        await queryClient.cancelQueries(fullAnalysisTaskListQueryKey);

        const previousData = queryClient.getQueryData<AxiosResponse<GetFullAnalysesStatusResponse>>(
          fullAnalysisTaskListQueryKey,
        );

        if (previousData) {
          const updatedTasks = previousData.data.response.map(task => {
            if (task.taskId === taskId) task.status = AnalysisStateNames.InProgress;
            return task;
          });
          const updatedData = merge({}, previousData, { data: { response: updatedTasks } });
          queryClient.setQueryData<AxiosResponse<GetFullAnalysesStatusResponse>>(
            fullAnalysisTaskListQueryKey,
            updatedData,
          );
        }

        return { previousData };
      },
      onError: (
        _err: string,
        _data,
        context: { previousData?: AxiosResponse<GetFullAnalysesStatusResponse> } | undefined,
      ) => {
        const errorMsg = 'Error encountered while rerunning full analysis task';
        logger.error(errorMsg);
        notifications.show({
          variant: 'error',
          message: errorMsg,
        });
        if (context?.previousData) {
          queryClient.setQueryData(fullAnalysisTaskListQueryKey, context.previousData);
        }
      },
    },
  );
};
