import {
  Badge,
  Center,
  Flex,
  Horizontal,
  Skeleton,
  Text,
  Tooltip,
  Vertical,
} from '../../../../design-system/v2';
import { OperatorCategory, OperatorModel } from '../../../../generated/api';
import { useGetOperatorListQuery } from '../../../../queries/workflows/operators';
import { OperatorIcon } from '../../../workspace/studio-home/workflow/OperatorIcon';
import { MAX_ICONS_TO_SHOW } from './util';

interface OperatorsIconListProps {
  operators: string[];
  category: OperatorCategory;
  maxIcons?: number;
}

export const OperatorsIconList = ({
  operators,
  category,
  maxIcons = MAX_ICONS_TO_SHOW,
}: OperatorsIconListProps) => {
  const { isError, isLoading, data: operatorList } = useGetOperatorListQuery();

  if (isLoading) {
    return (
      <Flex h="100%" w="100%" align="center">
        <Skeleton h={24} w={48} />
      </Flex>
    );
  }

  if (
    isError ||
    !operators ||
    operators.length === 0 ||
    !operatorList ||
    operatorList.length === 0
  ) {
    return (
      <Flex h="100%" w="100%" align="center">
        <Text color="gray.05"> -- -- </Text>
      </Flex>
    );
  }

  const operatorModels = operators
    .map(op => operatorList.find(operator => operator.operatorId === op))
    .filter(op => op?.category === category)
    .filter<OperatorModel>((op): op is OperatorModel => Boolean(op));

  const extraCount = operatorModels.length > maxIcons ? operatorModels.length - maxIcons : 0;

  const icons = operatorModels.slice(0, maxIcons).map((operator, index) => (
    <Tooltip key={index} label={operator.name} withinPortal>
      <Center>
        <OperatorIcon iconUrl={operator.iconUrl} size={20} />
      </Center>
    </Tooltip>
  ));

  return (
    <Flex h="100%" w="100%" align="center">
      <Horizontal spacing="md">
        {icons}
        {extraCount > 0 && (
          <Tooltip
            label={
              <Vertical>
                {operatorModels.slice(maxIcons).map((operator, index) => (
                  <Text key={index}>{operator.name}</Text>
                ))}
              </Vertical>
            }
            withinPortal
          >
            <Badge color="gray.3" bg="gray.1" variant="outline">
              <Text color="gray.6" variant="small03">
                +{extraCount}
              </Text>
            </Badge>
          </Tooltip>
        )}
      </Horizontal>
    </Flex>
  );
};
