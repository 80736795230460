import { IconInfoCircle } from '@tabler/icons-react';
import first from 'lodash/first';
import { ReactNode, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useWorkflowRunContext } from '../../../../contexts/workflows/WorkflowRunContext';
import {
  Alert,
  Center,
  Loader,
  useMarkovTheme,
  useMediaQuery,
  Vertical,
} from '../../../../design-system/v2';
import { WorkflowRun } from '../../../../generated/api';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { WorkflowRunsList } from './WorkflowRunsList';
import { WorkflowRunsListFooter } from './WorkflowRunsListFooter';
import { WorkflowRunsListTabs, WorkflowRunTabs } from './WorkflowRunsListTabs';

interface WorkflowRunsListWrapperProps {
  workflowId: string;
  currentPage: number;
  totalRows: number;
  workflowRuns: WorkflowRun[];
  isLoading: boolean;
  isError: boolean;
  activeTab: WorkflowRunTabs;
  onPageChange: (page: number) => void;
  onTabChange: (tabId: WorkflowRunTabs) => void;
}

export const WorkflowRunsListWrapper = ({
  workflowId,
  currentPage,
  totalRows,
  workflowRuns = [],
  isLoading,
  isError,
  activeTab,
  onPageChange,
  onTabChange,
}: WorkflowRunsListWrapperProps): JSX.Element => {
  const theme = useMarkovTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${theme.breakpoints.lg})`);
  const MIN_WORKFLOW_LIST_PANEL_WIDTH = isSmallScreen ? '360px' : '528px';
  const { setRunId } = useWorkflowRunContext();

  const { getWorkflowRunDetailsRoute } = useAbsoluteRoutes();
  const navigate = useNavigate();

  const handleWorkflowRunClick = (workflowRunId: string) => {
    setRunId(workflowRunId);
  };

  const handleWorkflowRunDetailsClick = (workflowRunId: string) => {
    const route = getWorkflowRunDetailsRoute(workflowId, workflowRunId);
    navigate(route);
  };

  useEffect(() => {
    setRunId(first(workflowRuns)?.workflowRunId ?? '');
  }, [workflowRuns]);

  let content: ReactNode;

  if (isLoading) {
    // TODO: Replace this with overlay loader
    content = (
      <Center h="100%">
        <Loader text="Loading workflow runs" />
      </Center>
    );
  } else if (isError) {
    content = (
      <Center h="100%">
        <Alert color="red" icon={<IconInfoCircle />}>
          There was an error while fetching workflow runs. Please try again or contact support
        </Alert>
      </Center>
    );
  } else if (!totalRows) {
    // TODO: Update the message based on the selected tab
    content = (
      <Center h="100%">
        <Alert icon={<IconInfoCircle />}>There are no workflow runs found</Alert>
      </Center>
    );
  } else {
    content = (
      <WorkflowRunsList
        workflowRuns={workflowRuns}
        onWorkflowRunClick={handleWorkflowRunClick}
        onWorkflowRunDetailsClick={handleWorkflowRunDetailsClick}
        activeTab={activeTab}
      />
    );
  }

  return (
    <Vertical h="100%" bg="white.0" miw={MIN_WORKFLOW_LIST_PANEL_WIDTH}>
      <WorkflowRunsListTabs activeTab={activeTab} onTabChange={onTabChange} />
      <Vertical spacing={0} sx={{ flexGrow: 1, overflowY: 'auto' }}>
        {content}
        <WorkflowRunsListFooter
          totalRows={totalRows}
          currentPage={currentPage}
          onPageChange={onPageChange}
        />
      </Vertical>
    </Vertical>
  );
};
