import { Horizontal, Image, LinkAnchor, Text, Vertical } from '../../../design-system/v2';
import { MARKOVML_PRODUCT_PRICING_PAGE } from '../../../developer-docs/links';

const ICON_URL =
  'https://ik.imagekit.io/markovml/payment-and-subscription/accomplishment-medal_clxO_JRbg.svg';

export const FreeTrialNotice = (): JSX.Element => (
  <Horizontal noWrap spacing="lg">
    <Image width={120} src={ICON_URL} sx={{ flexGrow: 0 }} />
    <Vertical>
      <Text variant="bodyLong01" sx={{ flex: 1 }}>
        Enjoy a{' '}
        <Text variant="bodyLong01" fw={600} span>
          free trial
        </Text>{' '}
        of the Team Plan to explore all our features. After the trial, choose the plan that suits
        you best!
      </Text>
      <LinkAnchor inline external href={MARKOVML_PRODUCT_PRICING_PAGE} target="_blank">
        <Text variant="bodyShort03" sx={theme => ({ color: theme.colors.blue[6] })}>
          Explore pricing plans
        </Text>
      </LinkAnchor>
    </Vertical>
  </Horizontal>
);
