import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import {
  ArtifactStateType,
  BaseAPIFilter,
  DataOperationType,
  ListDataOperationsResponse,
  Operator,
  SortOrder,
  UpdateDataOperationArtifactStateRequest,
} from '../../../generated/api';
import { datasetOperationApi } from '../../../lib/api';
import { HTTPError } from '../../../lib/api/api';
import { API_PAGE_SIZE } from '../../constants';

export const dataLabelingQueryKeys = {
  all: ['data-labeling'] as const,
  list: (workspaceId: string, filters?: object[], start?: number, limit?: number) =>
    [...dataLabelingQueryKeys.all, 'list', workspaceId, start, limit, filters] as const,
};

export const getDefaultLabelingFilters = (artifactState = ArtifactStateType.Active) => [
  {
    field: 'artifactState',
    operator: Operator.In,
    value: artifactState,
  },
  {
    field: 'type',
    operator: Operator.NotIn,
    value: [DataOperationType.FullAnalysis],
  },
];

export const useGetDataLabelingListQuery = ({
  filters = [],
  sortKey = 'updateDate',
  sortOrder = SortOrder.Desc,
  start = 0,
  limit = API_PAGE_SIZE,
}: {
  filters?: BaseAPIFilter[];
  sortKey?: string;
  sortOrder?: SortOrder;
  start?: number;
  limit?: number;
}) => {
  const { workspaceId } = useAppMetadata();

  return useQuery<
    AxiosResponse<ListDataOperationsResponse>,
    AxiosError<HTTPError>,
    ListDataOperationsResponse
  >(
    dataLabelingQueryKeys.list(workspaceId, filters, start, limit),
    () =>
      datasetOperationApi.listDataOperationsV2(workspaceId, {
        start,
        limit,
        sortKey,
        sortOrder,
        filters,
      }),
    {
      select: data => data.data,
      enabled: !!workspaceId,
    },
  );
};

export const useDataLabelingStateUpdateMutation = (
  dataLabelingArtifactState: UpdateDataOperationArtifactStateRequest,
) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  return useMutation(
    () => datasetOperationApi.dataOperationStateChangeV1(workspaceId, dataLabelingArtifactState),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(dataLabelingQueryKeys.all);
      },
    },
  );
};
