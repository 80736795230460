import noop from 'lodash/noop';
import { useAppMetadata } from '../../../../../contexts/app-metadata/AppMetadata';
import { Box, useMarkovTheme } from '../../../../../design-system/v2';
import { OperatorModel } from '../../../../../generated/api';
import { Form, FormSchema } from '../../../../common/form/Form';
import { Mode } from '../../utils';

interface NodeFormViewProps {
  workflowId: string;
  runId?: string;
  nodeId: string;
  prevNodeId?: string;
  operatorModel?: OperatorModel;
  data?: any;
  setData?: React.Dispatch<any>;
  isFormDirty?: boolean;
  setIsFormDirty?: React.Dispatch<React.SetStateAction<boolean>>;
  initialState?: any;
  onFormSubmit?: (data: any) => void;
  viewOnly?: boolean;
  isFormSaving?: boolean;
  mode?: Mode;
}

export const NodeFormView = ({
  workflowId,
  runId,
  nodeId,
  operatorModel,
  data,
  setData,
  isFormDirty,
  setIsFormDirty,
  initialState,
  onFormSubmit = noop,
  viewOnly,
  prevNodeId,
  isFormSaving,
  mode,
}: NodeFormViewProps) => {
  const theme = useMarkovTheme();
  const { workspaceId } = useAppMetadata();

  return (
    <Box sx={{ borderTop: `1px solid ${theme.colors.gray[2]}` }} px="24px" py="16px">
      {/* Position: relative needed for nested table view with absolute positioning */}
      <Box h="76vh" pos="relative">
        <Form
          schema={operatorModel?.parameters as FormSchema}
          data={data}
          setData={setData}
          isFormDirty={isFormDirty}
          setIsFormDirty={setIsFormDirty}
          initialState={initialState}
          onSubmit={onFormSubmit}
          onChange={noop}
          onErrors={noop}
          viewOnly={viewOnly}
          operator={operatorModel}
          workflowId={workflowId}
          runId={`${workspaceId}-${workflowId}-debug-run`}
          nodeId={nodeId}
          prevNodeId={prevNodeId ?? ''}
          isFormSaving={isFormSaving}
          mode={mode}
        />
      </Box>
    </Box>
  );
};
