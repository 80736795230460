import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const ModelApps = lazyWithRetry(() => import('../pages/ModelApps/ModelApps'));
const ModelAppDetails = lazyWithRetry(() => import('../pages/ModelApps/ModelAppDetails'));
const ModelAppDrift = lazyWithRetry(() => import('../pages/ModelApps/ModelAppDrift'));
const MizzenAppDetails = lazyWithRetry(() => import('../pages/ModelApps/MizzenAppDetails'));
const ModelAppRelabelOutputPage = lazyWithRetry(
  () => import('../pages/ModelApps/ModelAppRelabelling'),
);

const modelAppsRoutes: RouteProps[] = [
  {
    path: '/',
    element: <ModelApps />,
  },
  {
    path: getRoute(AppRoutes.MODEL_APP_DETAILS),
    element: <ModelAppDetails />,
  },
  {
    path: getRoute(AppRoutes.MODEL_APPS_MIZZEN),
    element: <MizzenAppDetails />,
  },
  {
    path: `${getRoute(AppRoutes.MODEL_APP_DETAILS)}/${getRoute(AppRoutes.MODEL_APP_RELABELLING)}`,
    element: <ModelAppRelabelOutputPage />,
  },
  {
    path: getRoute(AppRoutes.MODEL_APP_DRIFT),
    element: <ModelAppDrift />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const ModelAppsRouter = (): JSX.Element => (
  <Routes>{modelAppsRoutes.map(renderAuthRoute)}</Routes>
);
