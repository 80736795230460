import { ICellRendererParams } from '@ag-grid-community/core';
import { OperatorCategory, Workflow } from '../../../../generated/api';
import { OperatorsIconList } from './OperatorIconsList';

export const SourceOperatorRenderer = ({
  value: operators,
}: ICellRendererParams<Workflow, string[]>) => {
  if (!operators || operators.length === 0) {
    return <span>-</span>;
  }

  return (
    <OperatorsIconList operators={operators} category={OperatorCategory.Source} maxIcons={1} />
  );
};
