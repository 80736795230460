import camelCase from 'lodash/camelCase';
import mapKeys from 'lodash/mapKeys';
import { WorkflowRunContextProvider } from '../../../contexts/workflows/WorkflowRunContext';
import { Alert, Box, Center, Loader } from '../../../design-system/v2';
import { TemplateModel, UpsertDAGRequest } from '../../../generated/api';
import {
  useGetOperatorsPublicListQuery,
  useGetTemplatesPublicListQuery,
} from '../../../queries/workflows/operators';
import { WorkflowViewer } from '../detail/viewer/WorkflowViewer';

interface PublicTemplatesContainerProps {
  templateId: string;
}

const toCamelCase = (data: any): any => {
  if (Array.isArray(data)) {
    return data.map(item => {
      if (item !== null && typeof item === 'object' && !Array.isArray(item)) {
        return mapKeys(item, (_value, key) => camelCase(key));
      }
      return item;
    });
  }
  return data;
};

export const PublicTemplatesContainer = ({
  templateId,
}: PublicTemplatesContainerProps): JSX.Element => {
  const { isLoading, isError, data } = useGetTemplatesPublicListQuery();
  const {
    isLoading: operatorListLoading,
    isError: operatorListError,
    data: operatorList,
  } = useGetOperatorsPublicListQuery();

  const requiredTemplate = (data?.templates as TemplateModel[])?.find(
    template => template.templateId === templateId,
  );

  if (isLoading || operatorListLoading) {
    return (
      <Center h="100%">
        <Loader text="Loading template..." />
      </Center>
    );
  }

  if (isError || operatorListError || !requiredTemplate) {
    return <Alert color="red">Unable to find the template. Please try again</Alert>;
  }

  return (
    <Box bg="gray.1" h="100%">
      <WorkflowRunContextProvider workflowId="">
        <WorkflowViewer
          dag={requiredTemplate.dag as UpsertDAGRequest}
          operatorsList={toCamelCase(operatorList)}
          renderNodeActions
          isPublicTemplate
        />
      </WorkflowRunContextProvider>
    </Box>
  );
};
