import { useFlags } from 'launchdarkly-react-client-sdk';
import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { AppStoreFlagProvider } from '../pages/AppStore/AppStoreFlagProvider';
import { UnavailablePage } from '../pages/Unavailable';
import { renderAuthRoute } from './AuthRouter';
import { CopyEditRouter } from './CopyEditRouter';
import { DocQnARouter } from './DocQnARouter';
import { SummarizationRouter } from './SummarizationRouter';
import { AppRoutes, getDescendantRoute, getRoute } from './constants';
import { RetailAiRouter } from './retail-ai/RetailAiRouter';
import { lazyWithRetry } from './util';

const AppStorePage = lazyWithRetry(() => import('../pages/AppStore/AppStore'));
const ChatWithMarko = lazyWithRetry(() => import('../pages/AppStore/ChatWithMarko/ChatWithMarko'));
const MyGenAIApp = lazyWithRetry(() => import('../pages/AppStore/MyGenAIApp/MyGenAIApp'));

const useAppStoreRoutes = (): RouteProps[] => {
  const { featureCopyEdit } = useFlags();

  return [
    {
      path: '/',
      element: <AppStorePage />,
    },
    {
      path: getDescendantRoute(AppRoutes.SUMMARIZATION),
      element: <SummarizationRouter />,
    },
    {
      path: getDescendantRoute(AppRoutes.COPY_EDIT),
      element: featureCopyEdit ? <CopyEditRouter /> : <UnavailablePage />,
    },
    {
      path: getDescendantRoute(AppRoutes.CHAT_WITH_MARKO),
      element: <ChatWithMarko />,
    },
    {
      path: getDescendantRoute(AppRoutes.DOC_QNA),
      element: <DocQnARouter />,
    },
    {
      path: getDescendantRoute(AppRoutes.RETAIL_AI),
      element: <RetailAiRouter />,
    },
    {
      path: getRoute(AppRoutes.ERROR_ROUTE),
      element: <UnavailablePage />,
    },
    {
      path: '*',
      element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
    },
  ];
};

export const renderAppStoreRoute = ({ path, element }: RouteProps): JSX.Element => {
  const featureFlaggedElement = <AppStoreFlagProvider>{element}</AppStoreFlagProvider>;
  return renderAuthRoute({ path, element: featureFlaggedElement });
};

export const AppStoreRouter = (): JSX.Element => {
  const appStoreRoutes = useAppStoreRoutes();
  return <Routes>{appStoreRoutes.map(renderAppStoreRoute)}</Routes>;
};
