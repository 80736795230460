import { IconX } from '@tabler/icons-react';
import {
  BaseEdge,
  EdgeLabelRenderer,
  EdgeProps,
  getBezierPath,
  getSmoothStepPath,
} from 'reactflow';
import { useCreateWorkflow } from '../../../contexts/workflows/CreateWorkflow';
import { useWorkflowRunContext } from '../../../contexts/workflows/WorkflowRunContext';
import { ActionIcon, useMarkovTheme } from '../../../design-system/v2';
import { WorkflowRunOperatorStatus } from '../../../generated/api';
import { useDebugRunStatusQuery } from '../../../queries/workflows/debug';
import { EdgeTypes, useEdgeColor } from './util';

export const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  source,
  sourcePosition,
  targetPosition,
  data,
}: EdgeProps) => {
  const theme = useMarkovTheme();
  const { workflowId, runId } = useWorkflowRunContext();
  const { data: runStatus } = useDebugRunStatusQuery(workflowId, runId);

  const color = useEdgeColor({
    operatorStatus:
      runStatus?.operatorsStatus?.find(status => status.nodeId === source)?.statusDetails.status ??
      WorkflowRunOperatorStatus.NotStarted,
    isHovered: data?.isHovered,
  });

  const [edgePath] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const style = {
    strokeWidth: 2,
    stroke: color,
    strokeDasharray: '5,5',
    animation: 'dashdraw 0.5s linear infinite',
  };

  const markerId = `arrow-${id}`;

  const markerEnd = `url(#${markerId})`;

  return (
    <>
      <defs>
        <marker
          id={markerId}
          markerWidth="10"
          markerHeight="10"
          refX="10"
          refY="5"
          orient="auto"
          markerUnits="strokeWidth"
        >
          <path d="M0,0 L10,5 L0,10 L2.5,5 z" fill={color} />
        </marker>
      </defs>
      <BaseEdge id={id} path={edgePath} style={style} markerEnd={markerEnd} />
    </>
  );
};

export const RemovableCustomEdge = (props: EdgeProps) => {
  const { onEdgeDelete } = useCreateWorkflow();
  const { id, sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition, data } = props;
  const [, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <>
      <CustomEdge {...props} />
      {data?.isHovered ? (
        <EdgeLabelRenderer>
          <ActionIcon
            onClick={() => onEdgeDelete(id)}
            bg="white.0"
            p="md"
            style={{
              // This element's offsetParent is EdgeLabelRenderer
              // which has position: absolute
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px, ${labelY}px)`,
              // Everything inside EdgeLabelRenderer has no pointer events by default.
              // Hence pointerEvents is set to all to enable pointer events
              pointerEvents: 'all',
              borderRadius: '50%',
            }}
          >
            <IconX style={{ flexShrink: 0 }} />
          </ActionIcon>
        </EdgeLabelRenderer>
      ) : null}
    </>
  );
};

export const edgeTypes = {
  [EdgeTypes.CUSTOM_EDGE]: RemovableCustomEdge,
};

export const edgeViewerTypes = {
  [EdgeTypes.CUSTOM_EDGE]: CustomEdge,
};
