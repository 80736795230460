import { IconReload } from '@tabler/icons-react';
import { useMemo } from 'react';
import { useThreadsItemContext } from '../../../contexts/chat-with-data/ThreadsItemContext';
import { Button } from '../../../design-system/v2';
import {
  ChatResourceTypes,
  useArchiveThreadsByResourceMutation,
  useChatWithDataSourceThreadConversationsQuery,
  useChatWithResourceThreadsListQuery,
} from '../../../queries/chat-with-data/chat';

export interface ResetConversationProps {
  appId: string;
}

export const ResetConversation = ({ appId }: ResetConversationProps) => {
  const { activeThread, setActiveThread } = useThreadsItemContext();
  const { data: threads } = useChatWithResourceThreadsListQuery(
    appId,
    ChatResourceTypes.CUSTOM_APP,
  );
  const { data } = useChatWithDataSourceThreadConversationsQuery(
    appId,
    activeThread?.threadId || '',
    ChatResourceTypes.CUSTOM_APP,
  );
  const conversations = useMemo(() => data?.pages.flatMap(page => page) || [], [data]);

  const { isLoading: isArchiving, mutateAsync: archiveThread } =
    useArchiveThreadsByResourceMutation(appId, ChatResourceTypes.CUSTOM_APP);

  const activeThreadId = threads?.pages?.flatMap?.(page => page?.response)?.at(0)?.threadId ?? '';

  const handleReset = async () => {
    await archiveThread([activeThreadId]);
    setActiveThread(undefined);
  };

  if (!conversations.length) {
    return null;
  }

  return (
    <Button
      variant="secondary"
      color="dark.5"
      leftIcon={<IconReload size={20} />}
      loading={isArchiving}
      onClick={handleReset}
    >
      Reset
    </Button>
  );
};
