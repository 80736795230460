import { Sx } from '@mantine/styles';
import { IconSearch } from '@tabler/icons-react';
import { CloseButton, TextInput, useInputState, useMarkovTheme } from '../../../design-system/v2';

interface SearchboxProps {
  initialQuery?: string;
  onSearch: (query: string) => void;
  padding?: string | number;
  margin?: string | number;
  variant?: 'small' | 'big';
  placeholder?: string;
  width?: string | number;
  sx?: Sx | (Sx | undefined)[] | undefined;
  textColor?: string;
  showIcon?: boolean;
}

export const Searchbox = ({
  initialQuery = '',
  onSearch,
  padding,
  margin = 'sm',
  variant = 'small',
  width,
  placeholder = 'Type here to search',
  sx,
  textColor = 'black',
  showIcon = true,
}: SearchboxProps) => {
  const theme = useMarkovTheme();
  const [searchQuery, setSearchQuery] = useInputState(initialQuery);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setSearchQuery(e);
    onSearch(newQuery);
  };

  const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      onSearch(searchQuery);
    }
  };

  const handleClearSearch = () => {
    setSearchQuery('');
    onSearch('');
  };

  return (
    <TextInput
      ariaLabel="Search operators"
      icon={
        showIcon && (
          <IconSearch
            strokeWidth="1.5px"
            color={theme.colors.gray[6]}
            size={variant === 'small' ? 16 : 24}
          />
        )
      }
      value={searchQuery}
      onChange={handleSearch}
      onKeyUp={handleKeyUp}
      rightSection={searchQuery.length > 0 && <CloseButton onClick={handleClearSearch} />}
      placeholder={placeholder}
      sx={sx}
      radius="sm"
      p={padding}
      w={width}
      m={margin}
      styles={{
        input: {
          color: textColor,
        },
      }}
    />
  );
};
