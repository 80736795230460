import { RouteProps, Routes } from 'react-router-dom';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { lazyWithRetry } from './util';

const RecordingsPage = lazyWithRetry(() => import('../pages/Recording/Recordings'));
const DeletedRecordingsPage = lazyWithRetry(() => import('../pages/Recording/DeletedRecordings'));
const RecordingDetailsPage = lazyWithRetry(() => import('../pages/Recording/RecordingDetails'));
const MutlimodelRecordingComparisonPage = lazyWithRetry(
  () => import('../pages/RecordingComparison/MultimodelRecordingComparison'),
);
const MultidatasetRecordingComparisonPage = lazyWithRetry(
  () => import('../pages/RecordingComparison/MultidatasetRecordingComparison'),
);
const MultimodelEvaluationComparisonPage = lazyWithRetry(
  () => import('../pages/EvaluationComparison/MultimodelEvaluationComparison'),
);

const recordingRoutes: RouteProps[] = [
  {
    path: '/',
    element: <RecordingsPage />,
  },
  {
    path: getRoute(AppRoutes.DELETED_RECORDINGS),
    element: <DeletedRecordingsPage />,
  },
  {
    path: getRoute(AppRoutes.RECORDING_DETAIL),
    element: <RecordingDetailsPage />,
  },
  {
    path: getRoute(AppRoutes.COMPARE_RECORDINGS_MULTIMODEL),
    element: <MutlimodelRecordingComparisonPage />,
  },
  {
    path: getRoute(AppRoutes.COMPARE_EVALUATIONS_MULTIMODEL),
    element: <MultimodelEvaluationComparisonPage />,
  },
  {
    path: getRoute(AppRoutes.COMPARE_RECORDINGS_MULTIDATASET),
    element: <MultidatasetRecordingComparisonPage />,
  },
];

export const RecordingsRouter = (): JSX.Element => (
  <Routes>{recordingRoutes.map(renderAuthRoute)}</Routes>
);
