import { IconInfoCircle } from '@tabler/icons-react';
import { useIsMutating } from '@tanstack/react-query';
import first from 'lodash/first';
import { useAppMetadata } from '../../../contexts/app-metadata/AppMetadata';
import { Alert, Center, Loader, useInputState } from '../../../design-system/v2';
import {
  appBuilderKeys,
  useGetAppBuilderDetailsQuery,
  useGetAvailableModelsForAppBuilderQuery,
} from '../../../queries/app-builder/app-builder';
import { AppBuilderDetailsFormContainer } from './AppBuilderDetailsForm.container';
import { AppBuilderDetailsHeaderContainer } from './AppBuilderDetailsHeader.container';

interface AppBuilderDetailsContainerProps {
  appId: string;
}

export const AppBuilderDetailsContainer = ({
  appId,
}: AppBuilderDetailsContainerProps): JSX.Element => {
  const { workspaceId } = useAppMetadata();
  // const [selectedModel, setSelectedModel] = useState<LLMModel | undefined>();

  // TODO: Use a single state variable formState
  const [prompt, setPrompt] = useInputState('');
  const [example, setExample] = useInputState('');

  const {
    isLoading: isLoadingAppBuilderDetails,
    isFetching: isFetchingAppBuilderDetails,
    error,
    data: appBuilderDetails,
  } = useGetAppBuilderDetailsQuery(appId);

  const numberOfMutations = useIsMutating({
    mutationKey: appBuilderKeys.save(workspaceId, appId),
  });

  const isUpdatingAppBuilderDetails = numberOfMutations > 0;

  const { isLoading: isLoadingModels, data: availableModels } =
    useGetAvailableModelsForAppBuilderQuery();
  const selectedModel = first(availableModels);

  // useEffect(() => {
  //   if (availableModels) {
  //     setSelectedModel(first(availableModels));
  //   }
  // }, [availableModels]);

  // const handleSelectedModelChange = (value: string | null) => {
  //   const model = availableModels?.find(model => model.modelId === value);
  //   setSelectedModel(model);
  // };

  // The publish button is disabled when the prompt is empty
  // or when the app properties is not sync with the backend
  const isPromptEmpty = !appBuilderDetails?.appProperties?.appPrompt;
  const hasAppPropertiesChanged =
    (appBuilderDetails?.appProperties?.appPrompt ?? '') !== prompt.trim() ||
    (first(appBuilderDetails?.appProperties?.examples) ?? '') !== example.trim();

  const isSavingChanges = isFetchingAppBuilderDetails || isUpdatingAppBuilderDetails;

  if (isLoadingAppBuilderDetails || isLoadingModels) {
    return (
      <Center h="100%">
        <Loader text="Loading details for the app builder..." />
      </Center>
    );
  }

  // TODO: Handle abort error
  if (error || !selectedModel || !appBuilderDetails) {
    return (
      <Center h="100%">
        <Alert icon={<IconInfoCircle size={14} />} color="red">
          Unable to load details of the app. Please try again after sometime or contact support!
        </Alert>
      </Center>
    );
  }

  return (
    <>
      <AppBuilderDetailsHeaderContainer
        appId={appId}
        isLoadingAppBuilderDetails={isLoadingAppBuilderDetails}
        appBuilderDetails={appBuilderDetails}
        isPromptEmpty={isPromptEmpty}
        isSavingChanges={isSavingChanges}
        hasAppPropertiesChanged={hasAppPropertiesChanged}
      />
      <AppBuilderDetailsFormContainer
        prompt={prompt}
        example={example}
        onPromptChange={setPrompt}
        onExampleChange={setExample}
        appId={appId}
        selectedModel={selectedModel!}
        appBuilderDetails={appBuilderDetails}
      />
    </>
  );
};
