import { Divider, Vertical } from '../../../design-system/v2';
import { GetWorkspaceCurrentSubscriptionDetailResponseModel } from '../../../generated/api';
import { PlanSection } from '../../settings/about/subscription/PlanSection';
import { FreeTrialNotice } from './FreeTrialNotice';

interface SubscriptionDropdownProps {
  subscriptionDetail: GetWorkspaceCurrentSubscriptionDetailResponseModel;
  isTrialing: boolean;
}

export const SubscriptionDropdown = ({
  subscriptionDetail,
  isTrialing,
}: SubscriptionDropdownProps) => (
  <Vertical px="xxl" py="xl" spacing="xl">
    <PlanSection subscriptionDetail={subscriptionDetail} />
    {isTrialing && (
      <>
        <Divider sx={theme => ({ border: `1px solid ${theme.colors.gray[2]}` })} />
        <FreeTrialNotice />
      </>
    )}
  </Vertical>
);
