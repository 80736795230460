import { useQueryClient } from '@tanstack/react-query';
import debounce from 'lodash/debounce';
import {
  PropsWithChildren,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Connection,
  EdgeChange,
  EdgeMouseHandler,
  NodeChange,
  NodePositionChange,
  ReactFlowInstance,
  XYPosition,
  addEdge,
  updateEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import { workflowEvents } from '../../analytics';
import { Mode } from '../../components/workflows/create/utils';
import {
  isInvalidConnection,
  transformEdgeToWorkflowEdge,
  transformWorkflowEdgeToEdge,
} from '../../components/workflows/edges/util';
import {
  EdgeData,
  NodeData,
  WorkflowEdge,
  WorkflowNode,
  createWorkflowNode,
  findNodesAfter,
  positionCalculator,
  transformNodeToWorkflowNode,
  transformWorkflowNodeToNode,
} from '../../components/workflows/nodes/utils';
import { Edge, Node, OperatorCategory, OperatorModel, UpsertDAGRequest } from '../../generated/api';
import { sendAnalytics } from '../../initializers/analytics';
import { queryKeys } from '../../queries/resource-lookup';
import { useSaveWorkflowGraphMutation } from '../../queries/workflows/builder';
import { workflowsQueryKeys } from '../../queries/workflows/list/list';
import { operatorKeys } from '../../queries/workflows/operators';
import { useAppMetadata } from '../app-metadata/AppMetadata';

interface CreateWorkflowContext {
  workflowId: string;

  dagId: string;
  setDagId: (dagId: string) => void;

  nodes: WorkflowNode[];
  setNodes: React.Dispatch<SetStateAction<WorkflowNode[]>>;
  onNodesChange: (changes: NodeChange[]) => void;

  edges: WorkflowEdge[];
  setEdges: React.Dispatch<SetStateAction<WorkflowEdge[]>>;
  onEdgesChange: (changes: EdgeChange[]) => void;

  reactFlowInstance: ReactFlowInstance<any, any> | null;
  setReactFlowInstance: React.Dispatch<SetStateAction<ReactFlowInstance<any, any> | null>>;

  mode: Mode;
  setMode: (mode: CreateWorkflowContext['mode']) => void;

  onNodeAdd: (
    operatorId: string,
    nodeCategory: OperatorCategory,
    position?: XYPosition,
    name?: string,
  ) => WorkflowNode | undefined;
  onNodeDelete: (id: string) => void;

  onEdgeDelete: (edgeId: string) => void;
  onEdgeConnect: (params: Connection, checkCycle?: boolean) => void;
  onEdgeUpdateStart: () => void;
  onEdgeUpdate: (oldEdge: Edge, newConnection: Connection) => void;
  onEdgeUpdateEnd: (event: MouseEvent | TouchEvent, edge: Edge) => void;
  onEdgeMouseEnter: EdgeMouseHandler;
  onEdgeMouseLeave: EdgeMouseHandler;

  saveWorkflowDAG: (
    nodes: WorkflowNode[],
    edges: WorkflowEdge[],
    invalidateSchema?: boolean,
    nodesList?: string[],
  ) => void;
  isSaving: boolean;
}

const CreateWorkflowContext = createContext<CreateWorkflowContext | undefined>(undefined);

export const useCreateWorkflow = () => {
  const context = useContext(CreateWorkflowContext);

  if (context === undefined) {
    throw new Error('useCreateWorkflow must be used within a CreateWorkflowProvider');
  }

  return context;
};

interface CreateWorkflowProviderProps {
  workflowId: string;
  dag: UpsertDAGRequest;
  operators: OperatorModel[];
}

export const CreateWorkflowProvider = ({
  children,
  workflowId,
  dag,
  operators,
}: PropsWithChildren<CreateWorkflowProviderProps>) => {
  const { workspaceId } = useAppMetadata();
  const queryClient = useQueryClient();

  const [nodes, setNodes, onNodesChange] = useNodesState<NodeData>(
    dag.nodes.map(node => transformNodeToWorkflowNode(node, operators)),
  );
  const [edges, setEdges, onEdgesChange] = useEdgesState<EdgeData>(
    dag.edges.map(edge => transformEdgeToWorkflowEdge(edge)),
  );
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<any, any> | null>(
    null,
  );
  const [mode, setMode] = useState<Mode>(Mode.Build);
  const [dagId, setDagId] = useState('');
  const edgeUpdateSuccessful = useRef(true);

  const { mutateAsync: saveWorkflow, isLoading: isSaving } =
    useSaveWorkflowGraphMutation(workflowId);

  const saveWorkflowDAG = (
    nodes: WorkflowNode[],
    edges: WorkflowEdge[],
    invalidateSchema?: boolean,
    nodesList?: string[],
  ) => {
    const newNodes = nodes.map<Node>(node => transformWorkflowNodeToNode(node));
    const newEdges = edges.map<Edge>(edge => transformWorkflowEdgeToEdge(edge));
    const req: UpsertDAGRequest = {
      nodes: newNodes,
      edges: newEdges,
    };

    saveWorkflow(req, {
      onSuccess: data => {
        setDagId(data.data.dagId);

        queryClient.invalidateQueries(workflowsQueryKeys.dag(workspaceId, workflowId));
        if (invalidateSchema) {
          queryClient.invalidateQueries(operatorKeys.getDagIOSchema(workspaceId, workflowId));
          queryClient.invalidateQueries(queryKeys.list(workspaceId));
          if (nodesList && nodesList.length > 0) {
            nodesList.forEach(nodeId => {
              queryClient.invalidateQueries(
                operatorKeys.getWorkflowDagNodesSchema(workspaceId, workflowId, undefined, nodeId),
              );
            });
          }
        }
      },
    });
  };

  const saveWorkflowDAGDebounced = useCallback(debounce(saveWorkflowDAG, 500), []);

  const onNodeAdd = (
    operatorId: string,
    nodeCategory: OperatorCategory,
    position?: XYPosition,
    name?: string,
  ) => {
    if (!reactFlowInstance) return;

    const calculateDefaultPosition = positionCalculator(nodes, edges, reactFlowInstance);
    const newNode: WorkflowNode = createWorkflowNode(
      operatorId,
      nodeCategory,
      position ?? calculateDefaultPosition,
      name,
    );

    setNodes(currentNodes => {
      const updatedNodes = currentNodes.concat(newNode);

      saveWorkflowDAGDebounced(updatedNodes, edges, true);

      return updatedNodes;
    });

    return newNode;
  };

  const onNodeDelete = (id: string) => {
    const newNodes = nodes.filter(node => node.id !== id);
    const newEdges = edges.filter(edge => edge.source !== id && edge.target !== id);

    setNodes(newNodes);
    setEdges(newEdges);

    const nodesAfter = findNodesAfter(id, edges);

    saveWorkflowDAGDebounced(newNodes, newEdges, true, nodesAfter);
  };

  const onEdgeDelete = (edgeId: string) => {
    const edge = edges.find(e => e.id === edgeId);

    const newEdges = edges.filter(edge => edge.id !== edgeId);
    setEdges(newEdges);

    const nodesAfter = findNodesAfter(edge?.target, newEdges);

    saveWorkflowDAGDebounced(nodes, newEdges, true, nodesAfter);
  };

  const onEdgeConnect = (connection: Connection, checkCycle = true) => {
    if (isInvalidConnection(connection, edges, nodes, checkCycle)) return;

    sendAnalytics(
      workflowEvents.dag.linkNodesWithEdge({
        nodeIds: [connection.source ?? '', connection.target ?? ''],
        workflowId,
        workspaceId,
      }),
    );
    const edge = { ...connection, type: 'custom-edge' };
    const newEdges = addEdge(edge, edges);
    setEdges(newEdges);

    const nodesAfter = findNodesAfter(connection.target ?? undefined, newEdges);

    if (checkCycle) {
      saveWorkflowDAGDebounced(nodes, newEdges, true, nodesAfter);
    }
  };

  const onEdgeUpdateStart = () => {
    edgeUpdateSuccessful.current = false;
  };

  const onEdgeUpdate = (oldEdge: Edge, newConnection: Connection) => {
    edgeUpdateSuccessful.current = true;
    const nodesAfter = findNodesAfter(newConnection.target ?? undefined, edges);

    const newEdges = updateEdge(oldEdge, newConnection, edges);
    setEdges(newEdges);

    saveWorkflowDAGDebounced(nodes, newEdges, true, nodesAfter);
  };

  const onEdgeUpdateEnd = (event: MouseEvent | TouchEvent, edge: Edge) => {
    if (!edgeUpdateSuccessful.current) {
      const nodesAfter = findNodesAfter(edge.target, edges);
      const newEdges = edges.filter(e => e.id !== edge.id);
      setEdges(newEdges);

      saveWorkflowDAGDebounced(nodes, newEdges, true, nodesAfter);
    }

    edgeUpdateSuccessful.current = true;
  };

  /**
   * Updates the `data` property of an edge with a matching `edgeId` in a list of edges.
   *
   * @param edges - The array of edges in which to update the data.
   * @param edgeId - The unique identifier of the edge to update.
   * @param data - The new data to be assigned to the matching edge.
   * @returns A new array of edges with the updated edge data.
   */
  const updateEdgeDataById = (edges: WorkflowEdge[], edgeId: string, data: EdgeData) =>
    edges.map(edge => {
      if (edge.id === edgeId) return { ...edge, data };
      return edge;
    });

  const onEdgeMouseEnter: EdgeMouseHandler = (_, edge: WorkflowEdge) => {
    const edgeId = edge.id;
    setEdges(edges => updateEdgeDataById(edges, edgeId, { isHovered: true }));
  };

  const onEdgeMouseLeave: EdgeMouseHandler = (_, edge: WorkflowEdge) => {
    const edgeId = edge.id;
    setEdges(edges => updateEdgeDataById(edges, edgeId, { isHovered: false }));
  };

  const value = useMemo(
    () => ({
      workflowId,

      dagId,
      setDagId,

      nodes,
      setNodes,
      onNodesChange: (changes: NodeChange[]) => {
        onNodesChange(changes);

        const hasSelectChange = changes.some(
          change =>
            change.type === 'select' ||
            change.type === 'dimensions' ||
            (change.type === 'position' && !(change as NodePositionChange).dragging),
        );

        if (hasSelectChange) {
          return;
        }

        saveWorkflowDAGDebounced(nodes, edges, true);
      },

      edges,
      setEdges,
      onEdgesChange: (changes: EdgeChange[]) => {
        onEdgesChange(changes);

        const hasSelectChange = changes.some(change => change.type === 'select');

        if (hasSelectChange) {
          return;
        }
        saveWorkflowDAGDebounced(nodes, edges);
      },

      reactFlowInstance,
      setReactFlowInstance,

      mode,
      setMode,

      onNodeAdd,
      onNodeDelete,

      onEdgeDelete,
      onEdgeConnect,
      onEdgeUpdateStart,
      onEdgeUpdate,
      onEdgeUpdateEnd,
      onEdgeMouseEnter,
      onEdgeMouseLeave,

      saveWorkflowDAG,
      isSaving,
    }),
    [
      workflowId,
      dagId,
      nodes,
      edges,
      mode,
      reactFlowInstance,
      onNodesChange,
      onEdgesChange,
      saveWorkflowDAG,
      isSaving,
    ],
  );

  return <CreateWorkflowContext.Provider value={value}>{children}</CreateWorkflowContext.Provider>;
};
