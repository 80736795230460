import { Navigate, RouteProps, Routes } from 'react-router-dom';
import { WorkflowsListHomePage } from '../../components/workflows/home/WorkflowsListHomePage';
import { ArtifactStateType } from '../../generated/api';
import { UnavailablePage } from '../../pages/Unavailable';
import { renderAuthRoute } from '../AuthRouter';
import { AppRoutes, getDescendantRoute, getRoute } from '../constants';
import { lazyWithRetry } from '../util';
import { DetailRouter } from './detail';

const Workflows = lazyWithRetry(() => import('../../pages/Workflows/Workflows'));

const workflowDetail = getDescendantRoute(AppRoutes.WORKFLOW_DETAIL);

const workflowRoutes: RouteProps[] = [
  {
    path: '/',
    element: <Workflows />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_TEMPLATES),
    element: <Workflows />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_LIST),
    element: <WorkflowsListHomePage />,
  },
  {
    path: getRoute(AppRoutes.WORKFLOWS_ARCHIVED),
    element: <WorkflowsListHomePage state={ArtifactStateType.Archived} />,
  },
  {
    path: workflowDetail,
    element: <DetailRouter />,
  },
  {
    path: getRoute(AppRoutes.ERROR_ROUTE),
    element: <UnavailablePage />,
  },
  {
    path: '*',
    element: <Navigate to={getRoute(AppRoutes.ERROR_ROUTE)} />,
  },
];

export const WorkflowsRouter = (): JSX.Element => (
  <Routes>{workflowRoutes.map(renderAuthRoute)}</Routes>
);
