import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { workflowEvents } from '../../../../analytics';
import { useAppMetadata } from '../../../../contexts/app-metadata/AppMetadata';
import {
  Alert,
  Box,
  Button,
  Center,
  Flex,
  Loader,
  LoadingOverlay,
  ScrollArea,
  Text,
  Vertical,
  closeAllModals,
  notifications,
} from '../../../../design-system/v2';
import { sendAnalytics } from '../../../../initializers/analytics';
import { formatDateTime } from '../../../../lib/ui';
import { useCreateWorkflowMutation } from '../../../../queries/workflows/builder';
import {
  useGetOperatorListQuery,
  useGetTemplatesListQuery,
} from '../../../../queries/workflows/operators';
import { useAbsoluteRoutes } from '../../../../router/hooks';
import { Searchbox } from '../../../common/search-box/Searchbox';
import { TemplateList } from './TemplateList';
import { getTemplateIdSearchParam } from './util';

export const TemplatesModalContainer = () => {
  const { workspaceId } = useAppMetadata();
  const navigate = useNavigate();
  const { getWorkflowBuilderRoute } = useAbsoluteRoutes();
  const [searchText, setSearchText] = useState('');

  const { mutateAsync: createWorkflow, isLoading: creatingWorkflow } = useCreateWorkflowMutation();
  const { isLoading, isError, data } = useGetTemplatesListQuery();
  const {
    isLoading: operatorListLoading,
    isError: operatorListError,
    data: operatorList,
  } = useGetOperatorListQuery();

  const handleSearch = (q: string) => setSearchText(q);
  const handleAddWorkflow = async (templateId?: string, workflowName?: string) => {
    try {
      const data = await createWorkflow({ workflowName, templateId });
      if (data?.data?.workflowId) {
        navigate({
          pathname: getWorkflowBuilderRoute(data.data.workflowId),
          search: `${templateId?.length === 0 ? '' : getTemplateIdSearchParam(templateId)}`,
        });
      }
    } catch (e) {
      notifications.error('Error while creating workflow. Please try again and contact support');
    }
    closeAllModals();
  };

  const createBlankWorkflow = () => {
    sendAnalytics(workflowEvents.create.blank({ workspaceId, source: 'template-modal' }));
    handleAddWorkflow('', `Untitled-${formatDateTime(new Date())}`);
  };

  if (isLoading || operatorListLoading) {
    return (
      <Center h={400}>
        <Loader text="Loading workflow templates..." />
      </Center>
    );
  }

  if (isError || operatorListError || !data) {
    return (
      <Box py="lg">
        <Alert color="red">
          <Text color="gray.7" variant="bodyLong01">
            We have encountered an issue trying to load list of available templates. Please refresh
            and if issue persist, please contact support
          </Text>
        </Alert>
      </Box>
    );
  }

  const filteredData = data.filter(datum =>
    datum.name.toLowerCase().includes(searchText.toLowerCase()),
  );

  return (
    <Vertical pb="32px">
      <Flex justify="space-between">
        <Searchbox onSearch={handleSearch} padding="sm" width={400} />
        <LoadingOverlay visible={creatingWorkflow} />
        <Button variant="light" onClick={createBlankWorkflow} m="sm">
          New Blank Canvas
        </Button>
      </Flex>
      <ScrollArea.Autosize h="50vh" mah="50vh" offsetScrollbars>
        <TemplateList
          templates={filteredData}
          handleAddWorkflow={handleAddWorkflow}
          operatorList={operatorList ?? []}
        />
      </ScrollArea.Autosize>
    </Vertical>
  );
};
