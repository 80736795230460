import { useWorkflowRunContext } from '../../../../contexts/workflows/WorkflowRunContext';
import { createStyles, ScrollArea, VerticalList } from '../../../../design-system/v2';
import { WorkflowRun } from '../../../../generated/api';
import { getWorkflowListItemInfo } from './util';
import { WorkflowRunListItem } from './WorkflowRunListItem';
import { WorkflowRunTabs } from './WorkflowRunsListTabs';

const useListItemStyles = createStyles(theme => ({
  listItem: {
    borderRadius: '8px',
    borderBottom: `1.5px solid ${theme.colors.gray[1]}`,
    '.mantine-List-itemWrapper': {
      width: '100%',
    },
    cursor: 'pointer',
    ':hover': {
      background: theme.colors.gray[1],
    },
  },
  selected: {
    background: theme.colors.blue[1],
    ':hover': {
      background: theme.colors.blue[1],
    },
  },
}));

export interface WorkflowRunsListProps {
  workflowRuns: Array<WorkflowRun>;
  onWorkflowRunClick: (id: string) => void;
  onWorkflowRunDetailsClick: (id: string) => void;
  activeTab: WorkflowRunTabs;
}

export const WorkflowRunsList = ({
  workflowRuns,
  onWorkflowRunClick,
  onWorkflowRunDetailsClick,
  activeTab,
}: WorkflowRunsListProps) => {
  const { runId } = useWorkflowRunContext();
  const { classes, cx } = useListItemStyles();

  return (
    <ScrollArea h="100%">
      <VerticalList spacing="xsm" px="lg" py="xxl" listStyleType="none" sx={{ flexGrow: 1 }}>
        {workflowRuns.map(run => {
          const { workflowRunId } = run;
          const props = getWorkflowListItemInfo(run);
          const isSelected = workflowRunId === runId;

          const listItemClass = cx(classes.listItem, {
            [classes.selected]: isSelected,
          });

          return (
            <VerticalList.Item
              key={workflowRunId}
              px="sm"
              py="md"
              onClick={() => onWorkflowRunClick(workflowRunId)}
              className={listItemClass}
            >
              <WorkflowRunListItem
                {...props}
                activeTab={activeTab}
                workflowRunId={workflowRunId}
                onWorkflowRunDetailsClick={onWorkflowRunDetailsClick}
              />
            </VerticalList.Item>
          );
        })}
      </VerticalList>
    </ScrollArea>
  );
};
