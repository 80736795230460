import { Route, RouteProps, Routes } from 'react-router-dom';
import { renderAuthRoute } from './AuthRouter';
import { AppRoutes, getRoute } from './constants';
import { ProjectDetailsRouter } from './ProjectDetailsRouter';
import { lazyWithRetry } from './util';

const ProjectsPage = lazyWithRetry(() => import('../pages/Projects/Projects'));

const projectRoutes: RouteProps[] = [
  {
    path: '/',
    element: <ProjectsPage />,
  },
];

export const ProjectsRouter = (): JSX.Element => (
  <Routes>
    {projectRoutes.map(renderAuthRoute)}
    <Route path={`${getRoute(AppRoutes.PROJECT_DETAILS)}/*`} element={<ProjectDetailsRouter />} />
  </Routes>
);
